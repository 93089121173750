import { useState } from "react";

/**
 * Custom hook for handling form inputs.
 *
 * @template T The type of the form values object.
 * @param {T} initialState The initial state of the form values.
 * @returns An object containing the form values and a function to update them.
 */
const useInput = <T extends Record<string, any>>(initialState: T) => {
    // State to store the current form values
    const [catalogItemValues, setCatalogItemValues] = useState<T>(initialState);

    /**
     * Function to handle changes in form inputs.
     *
     * @param {keyof T} field The field name whose value needs to be updated.
     * @param {string | string[]} value The new value of the input field.
     */
    const handleInputChange = (field: keyof T, value: string | string[]) => {
        // Update the CatalogItemValues state with the new value for the specified field
        setCatalogItemValues((prev) => ({ ...prev, [field]: value }));
    };

    /**
     * Updates multiple catalog items values at once based on a provided object.
     *
     * @param {Partial<T>} valuesObj An object containing fields and their values to be updated.
     */
    const setCatalogItemValuesBulk = (valuesObj: Partial<T>) => {
        setCatalogItemValues((prev) => ({
            ...prev,
            ...valuesObj,
        }));
    };

    // Return the form values, input change handler and setCatalogItemValuesBulk
    return {
        catalogItemValues,
        handleInputChange,
        setCatalogItemValuesBulk,
    };
};

export default useInput;
