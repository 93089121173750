import { Page } from "../../interfaces";
import { getString } from "../ui-string-labels/ui-strings-utils";

export default class Paths {
    // Manage orders page is being used as Home page

    static readonly Admin: Page = { name: "Admin", url: "/orders" };

    static readonly manageLicense: Page = { name: getString("manageLicense.table.headerTitle"), url: "/manageLicense" };
    static readonly uploadLicense: Page = { name: getString("uploadLicense.table.headerTitle"), url: "/uploadLicense" };
    static readonly licenseDetails: Page = {
        name: getString("LicenseDetails.table.headerTitle"),
        url: "/licenseDetails/:id",
    };

    static readonly manageOrders: Page = { name: getString("manageOrders.table.headerTitle"), url: "/orders" };
    static readonly uploadOrders: Page = { name: getString("uploadOrders.table.headerTitle"), url: "/orders/upload" };
    static readonly ordersUploaded: Page = { name: "Orders uploaded", url: "/orders/:id" };
    static readonly ordersDownloaded: Page = { name: "Downloaded orders", url: "/orders/dowloaded" };

    static readonly manageCatalog: Page = { name: getString("manageCatalog.table.headerTitle"), url: "/catalog" };
    static readonly createCatalog: Page = {
        name: getString("createCatalog.formLabels.formHeader"),
        url: "/catalog/create",
    };
    static readonly editCatalog: Page = {
        name: getString("editCatalog.formHeader"),
        url: "/catalog/edit/:id",
    };
}
