import { useCollection } from "@amzn/awsui-collection-hooks";
import { SortingState, UseCollectionResult } from "@amzn/awsui-collection-hooks/cjs/interfaces";
import {
    CollectionPreferencesProps,
    FlashbarProps,
    PropertyFilterProps,
    SelectProps,
    TableProps,
} from "@amzn/awsui-components-react";
import { NonCancelableEventHandler } from "@amzn/awsui-components-react/polaris/internal/events";
import { Preferences } from "common/ui-helpers";
import { useFlashBarItems } from "common/UseFlashBarItems/useFlashBarItems";
import { buildColumnDefinitions, buildVisibleContentOptions, defaultPagingOptions } from "common/utils";
import { usePreferences } from "hooks/usePreferences";
import { useEffect, useState } from "react";

import { CatalogicalServiceV2 } from "../../services/catalogical-service-v2";
import itemTableConfig from "./items.table-config.json";

interface CatalogItem {
    name: string;
    status?: string;
    categories?: string[];
    sku: string;
    price: {
        amount: number;
        currency: string;
    };
    has_stock: boolean;
    catalogicalGenerated: {
        created: {
            on: string;
        };
        updated: {
            on: string;
        };
    };
    taxonomyId: string;
    manufacturer?: string;
    subtype?: string;
    short_name?: string;
    restock_threshold?: number;
}

interface UseManageCatalogTableFiltering {
    fuzzySearch: PropertyFilterProps.Token[];
    propertyFilterTokens: PropertyFilterProps.Token[];
    onFilterChange: (event: { detail: PropertyFilterProps.Query }) => void;
    filteringProperties: PropertyFilterProps.FilteringProperty[];
    statusOptions: { label: string; value: string }[];
    isActiveOptions: { label: string; value: string }[];
    selectedStatus: { label: string; value: string };
    selectedActive: { label: string; value: string };
    onStatusChange: NonCancelableEventHandler<SelectProps.ChangeDetail>;
    onActiveChange: NonCancelableEventHandler<SelectProps.ChangeDetail>;
}

interface UseManageCatalogTablePagination {
    currentPage: number;
    setCurrentPage: (page: number) => void;
    pagesCount: number;
    onChangePageHandler: ({ detail }: { detail: any }) => void;
}

interface UseManageCatalogTablePreferences {
    visibleContentOptions: CollectionPreferencesProps.VisibleContentOption[];
    onPreferencesConfirmHandler: NonCancelableEventHandler<CollectionPreferencesProps.Preferences<any>>;
    currentPreferences: Preferences;
}

interface UseManageCatalogTableSorting {
    sortBy: string;
    sortDirection: "asc" | "desc";
    onSortingChange: NonCancelableEventHandler<TableProps.SortingState<CatalogItem>>;
}

interface UseManageCatalogTableData {
    catalogItems: CatalogItem[];
    isLoading: boolean;
    refetchCatalogItems: () => void;
    totalItems: number;
    currentColumnDefinitions: TableProps.ColumnDefinition<any>[];
    collectionProps: UseCollectionResult<CatalogItem>["collectionProps"];
    onSelectionChangeHandler: ({ detail }: { detail: any }) => void;
    selectedItems: any[];
    setSelectedItems: (items: any[]) => void;
    isLeaveModalVisible: boolean;
    setIsLeaveModalVisible: (isVisible: boolean) => void;
    submitStatus: { loading: boolean };
    setSubmitStatus: (status: { loading: boolean }) => void;
    handleDeactivateClick: () => void;
    handleDismissModal: () => void;
    handleDeactivateItem: () => Promise<void>;
    flashBarItems: readonly FlashbarProps.MessageDefinition[];
    counterText: string;
}

export interface UseManageCatalogTableResult {
    filtering: UseManageCatalogTableFiltering;
    pagination: UseManageCatalogTablePagination;
    preferences: UseManageCatalogTablePreferences;
    sorting: UseManageCatalogTableSorting;
    data: UseManageCatalogTableData;
}

export function useManageCatalogTable(): UseManageCatalogTableResult {
    const [catalogItems, setCatalogItems] = useState<CatalogItem[]>([]);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [sortBy, setSortBy] = useState<string>("name");
    const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
    const [filterQuery, setFilterQuery] = useState<PropertyFilterProps.Query>({ tokens: [], operation: "and" });
    const [fuzzySearch, setFuzzySearch] = useState<PropertyFilterProps.Token[]>([]);
    const [propertyFilterTokens, setPropertyFilterTokens] = useState<PropertyFilterProps.Token[]>([]);
    const [sortingState, setSortingState] = useState<SortingState<CatalogItem>>();
    const [statusFilter, setStatusFilter] = useState<string>("");
    const [activeFilter, setActiveFilter] = useState<string>("true");
    const [selectedItems, setSelectedItems] = useState<any[]>([]);
    const { actions, flashBarItems } = useFlashBarItems();
    const [isLeaveModalVisible, setIsLeaveModalVisible] = useState(false);
    const [submitStatus, setSubmitStatus] = useState<{ loading: boolean }>({ loading: false });

    const pagesCount = Math.ceil(totalItems / pageSize);
    const columns = itemTableConfig.columns;
    const preferencesKey = "ManageCatalogPreferences";

    //Uses usePreferences to handle the page size and column visibility
    const { currentPreferences, onPreferencesConfirmHandler } = usePreferences({
        preferencesKey: preferencesKey,
        refreshData: () => {
            setPageSize(currentPreferences.pageSize || 10);
            setCurrentPage(1);
        },
        columnsSettings: columns,
        defaultPaging: defaultPagingOptions[0].value,
    });

    const currentColumnDefinitions = buildColumnDefinitions(columns, currentPreferences.visibleContent);
    const visibleContentOptions = buildVisibleContentOptions(columns);

    // Options for the filtering properties
    const filteringProperties = [
        { key: "name", propertyLabel: "Product name", operators: ["="], groupValuesLabel: "Product name values" },
        { key: "sku", propertyLabel: "SKU", operators: ["="], groupValuesLabel: "SKU values" },
        { key: "taxonomyId", propertyLabel: "Taxonomy ID", operators: ["="], groupValuesLabel: "Taxonomy ID Value" },
        { key: "categories", propertyLabel: "Category", operators: ["="], groupValuesLabel: "Category Value" },
        {
            key: "manufacturer",
            propertyLabel: "Manufacturer",
            operators: ["="],
            groupValuesLabel: "Manufacturer Value",
        },
        { key: "os", propertyLabel: "Operating System", operators: ["="], groupValuesLabel: "Operating System Value" },
    ];

    // Options for Status select
    const statusOptions = [
        { label: "Select Status", value: "" },
        { label: "Sanctioned", value: "sanctioned" },
        { label: "Unsanctioned", value: "unsanctioned" },
        { label: "Unavailable", value: "unavailable" },
    ];

    //Options for isActive select
    const isActiveOptions = [
        { label: "Active", value: `${true}` },
        { label: "Inactive", value: `${false}` },
    ];

    const [selectedStatus, setSelectedStatus] = useState(statusOptions[0]);
    const [selectedActive, setSelectedActive] = useState(isActiveOptions[0]);

    // Handles the change of selection
    /* istanbul ignore next */
    const onSelectionChangeHandler = ({ detail }: { detail: any }) => {
        setSelectedItems(detail.selectedItems);
    };

    // Handles the change of Page
    /* istanbul ignore next */
    const onChangePageHandler = ({ detail }: { detail: any }) => {
        setCurrentPage(detail.currentPageIndex);
    };

    // Handles the change of the "status" select
    /* istanbul ignore next */
    const onStatusChange: NonCancelableEventHandler<SelectProps.ChangeDetail> = (e) => {
        const selectedValue = e.detail.selectedOption.value || "";
        setStatusFilter(selectedValue);
        setSelectedStatus({
            label: e.detail.selectedOption.label || "Select a status",
            value: selectedValue,
        });
        setCurrentPage(1);
    };

    // Handles the change of the "isActive" select
    /* istanbul ignore next */
    const onActiveChange: NonCancelableEventHandler<SelectProps.ChangeDetail> = (e) => {
        const selectedValue = e.detail.selectedOption.value || "";
        setActiveFilter(selectedValue);
        setSelectedActive({
            label: e.detail.selectedOption.label || "Select an active status",
            value: selectedValue,
        });
        setCurrentPage(1);
    };

    // Event handlers for filter change
    /* istanbul ignore next */
    const onFilterChange = (event: { detail: PropertyFilterProps.Query }) => {
        const tokens = event.detail.tokens; // Extracts filter tokens from the event detail.
        const isSwitchingToPropertyFilter = tokens.some((token) => token.propertyKey) && fuzzySearch.length > 0;

        // Determines if the user is switching from a fuzzy search to a property-based filter.

        if (isSwitchingToPropertyFilter) {
            // If switching to property filter, clear fuzzy search and set property filter tokens.
            setFuzzySearch([]);
            const propertyKeyFilters = tokens.filter((token) => token.propertyKey);
            setPropertyFilterTokens(propertyKeyFilters);
            setFilterQuery({ tokens: propertyKeyFilters, operation: "and" });
        } else {
            const isFuzzySearch = tokens.some((token) => !token.propertyKey);
            // Determines if the current filter is a fuzzy search (no specific property key).

            if (isFuzzySearch) {
                // If it's a fuzzy search, update fuzzy search tokens and clear property filters.
                const fuzzyKeywords = tokens.filter((token) => !token.propertyKey);
                setFuzzySearch(fuzzyKeywords);
                setPropertyFilterTokens([]);
                setFilterQuery({ tokens: fuzzyKeywords, operation: "and" });
            } else {
                // If using property filters, update property filter tokens and clear fuzzy search.
                setFuzzySearch([]);
                const propertyKeyFilters = tokens.filter((token) => token.propertyKey);
                setPropertyFilterTokens(propertyKeyFilters);
                setFilterQuery({ tokens: propertyKeyFilters, operation: "and" });
            }
        }
        setCurrentPage(1); // Resets to the first page to reflect the new filtering.
    };

    // useCollection hook to manage sorting, pagination, and selection within the table
    const { items: sortedAndPagedItems, collectionProps } = useCollection(catalogItems, {
        pagination: { pageSize: currentPreferences.pageSize, defaultPage: currentPage },
        sorting: { defaultState: sortingState },
        selection: { trackBy: "taxonomyId" },
    });

    /* istanbul ignore next */
    const onSortingChange: NonCancelableEventHandler<TableProps.SortingState<CatalogItem>> = async (event) => {
        const { sortingColumn, isDescending } = event.detail;
        const sortBy = sortingColumn.sortingField;
        const sortDirection = isDescending ? "desc" : "asc";
        setSortBy(sortBy || "");
        setSortDirection(sortDirection);
    };

    //Handles the visibility of the modal when clicking the deactivate button
    /* istanbul ignore next */
    const handleDeactivateClick = () => {
        setIsLeaveModalVisible(true);
    };

    //Handles the dismissal of the modal when the submit status isn't loading
    /* istanbul ignore next */
    const handleDismissModal = () => {
        if (!submitStatus.loading) {
            setIsLeaveModalVisible(false);
        }
    };

    //Handles the deactivation of all selected items
    /* istanbul ignore next */
    const handleDeactivateItem = async () => {
        if (selectedItems.length > 0) {
            // Iterate over each selected item
            for (const item of selectedItems) {
                const taxonomyId = item.taxonomyId;
                const name = item.name;
                setSubmitStatus({ loading: true });
                try {
                    await CatalogicalServiceV2.instance.deactivateCatalogItem(taxonomyId);
                    actions.addSuccessFlashBarItem(
                        `${name} succesfully deactivated! Changes will be available in a few seconds`,
                    );
                } catch (error) {
                    const errorMessage = (error as Error).message;
                    actions.addErrorFlashBarItem(`Error deactivating ${name}: ${errorMessage}`);
                }
            }
            setSelectedItems([]);
            setSubmitStatus({ loading: false });
            setIsLeaveModalVisible(false);
            fetchCatalogItems();
        }
    };

    //Text that shows the selected items and total items
    /* istanbul ignore next */
    const counterText =
        selectedItems.length > 0 ? `(${selectedItems.length}/${totalItems || 0})` : `(${totalItems || 0})`;

    const dateFormatter = new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
        timeZoneName: "short",
    });

    /* istanbul ignore next */
    const capitalizeString = (value: string): string => {
        return value.charAt(0).toUpperCase() + value.slice(1);
    };

    // Function to transform API response to CatalogItem type
    /* istanbul ignore next */
    function transformCatalogItem(item: any): CatalogItem {
        return {
            ...item,
            categories: item.categories?.join("\n"),
            price: item.price
                ? {
                      amount: item.price.amount,
                      currency: item.price.currency,
                  }
                : { amount: 0, currency: "N/A" },
            catalogicalGenerated: {
                created: {
                    on: dateFormatter.format(new Date(item.catalogicalGenerated.created.on)),
                },
                updated: {
                    on: dateFormatter.format(new Date(item.catalogicalGenerated.updated.on)),
                },
            },
            has_stock: item.has_stock ? "In Stock" : "Out of Stock",
            status: item.status ? capitalizeString(item.status.toLowerCase()) : "Unavailable",
            manufacturer: item.manufacturer ? capitalizeString(item.manufacturer) : undefined,
        };
    }

    // Function to fetch catalog items from the request, applying filters, sorting and pagination
    /* istanbul ignore next */
    const fetchCatalogItems = async () => {
        setLoading(true);
        try {
            const filtersObj: { [key: string]: string | boolean } = {};
            filterQuery.tokens.forEach((token) => {
                if (token.propertyKey) filtersObj[token.propertyKey] = token.value;
            });

            if (statusFilter) {
                filtersObj["status"] = statusFilter;
            }
            if (activeFilter) {
                filtersObj["catalogicalGenerated.isActive"] = activeFilter;
            }

            // Prepare other parameters
            const offset = (currentPage - 1) * pageSize;
            const filter = JSON.stringify(filtersObj);
            const fuzzy = fuzzySearch.length > 0;
            const keywords = fuzzySearch
                .map((token) => token.value)
                .join(" ")
                .trim();

            const response = await CatalogicalServiceV2.instance.getAllCatalogItems(
                pageSize,
                offset,
                sortBy,
                sortDirection,
                filter,
                fuzzy,
                keywords,
            );

            setCatalogItems(response.data.map(transformCatalogItem));
            setTotalItems(response.pagination.total);
        } catch (err) {
            console.error("Failed to fetch catalog items:", err);
        } finally {
            setLoading(false);
        }
    };

    // useEffect hook to refetch catalog items when dependencies change
    useEffect(() => {
        fetchCatalogItems();
    }, [currentPage, sortBy, sortDirection, filterQuery, sortingState, statusFilter, activeFilter, pageSize]);

    return {
        filtering: {
            fuzzySearch,
            propertyFilterTokens,
            onFilterChange,
            filteringProperties,
            statusOptions,
            isActiveOptions,
            selectedStatus,
            selectedActive,
            onStatusChange,
            onActiveChange,
        },
        pagination: {
            currentPage,
            setCurrentPage,
            pagesCount,
            onChangePageHandler,
        },
        preferences: {
            visibleContentOptions,
            onPreferencesConfirmHandler,
            currentPreferences,
        },
        sorting: {
            sortBy,
            sortDirection,
            onSortingChange,
        },
        data: {
            catalogItems,
            isLoading,
            refetchCatalogItems: fetchCatalogItems,
            totalItems,
            currentColumnDefinitions,
            collectionProps,
            onSelectionChangeHandler,
            selectedItems,
            setSelectedItems,
            isLeaveModalVisible,
            setIsLeaveModalVisible,
            submitStatus,
            setSubmitStatus,
            handleDeactivateClick,
            handleDismissModal,
            handleDeactivateItem,
            flashBarItems,
            counterText,
        },
    };
}
