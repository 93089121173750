import { SentryFetchHttpHandler } from "@amzn/sentry-fetch-http-handler";
import {
    DeactivateLicensesCommandInput,
    DeactivateLicensesCommandOutput,
    GetFilesCommandInput,
    GetFilesCommandOutput,
    GetInventoryCommandInput,
    GetInventoryCommandOutput,
    GetLicenseDetailsCommandInput,
    GetLicenseDetailsCommandOutput,
    TInvent,
    UpdateInventoryNoteCommandInput,
    UpdateInventoryNoteCommandOutput,
} from "@amzn/tinvent-typescript-client";
import { useEffect,useState } from "react";

import { API_BASE_URL } from "../configuration/config";
import { Error } from "./hook-helper";

export interface InventoryProps {
    pageSize: number;
    pageIndex: number;
    filters?: { [key: string]: string[] };
    clearPreviousData?: boolean;
    fromAutoRefresh?: boolean;
}

export interface GetLicenseDetailsProps {
    pageSize: number;
    pageIndex: number;
    productTaxonomyId?: string;
    filters?: { [key: string]: string[] };
    clearPreviousData?: boolean;
    assigned?: string;
}

export interface DeactivateLicensesProps {
    taxonomyIds: string[];
}

export function usePatchInventoryNote(
    defaultValue: UpdateInventoryNoteCommandOutput | undefined,
): [
    UpdateInventoryNoteCommandOutput | undefined,
    boolean,
    Error,
    (taxonomyIds: string[], csrfToken: string, note?: string) => void,
] {
    const [data, setData] = useState<UpdateInventoryNoteCommandOutput | undefined>(defaultValue);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [error, setError] = useState<Error>({ hasError: false });

    // Client
    const [client, requestHandler] = getTinventClient();

    async function doPatchUpdateInventoryNotes(taxonomyIds: string[], csrfToken: string, note?: string) {
        setIsProcessing(true);
        requestHandler.pushHeader("x-csrf-token", csrfToken);
        const request: UpdateInventoryNoteCommandInput = {
            taxonomyIds,
            note,
        };

        try {
            const response = await client.updateInventoryNote(request);
            setData(response);
        } catch (err) {
            const errorMessage = `Error updating the inventory notes: ${err}`;
            console.error(errorMessage);
            setError({
                hasError: true,
                message: errorMessage,
            } as Error);
        }
        setIsProcessing(false);
    }

    return [data, isProcessing, error, doPatchUpdateInventoryNotes];
}

export function useGetInventory(
    mainProps: InventoryProps,
    defaultValue: GetInventoryCommandOutput | undefined,
): [GetInventoryCommandOutput | undefined, boolean, Error, (props: InventoryProps) => void] {
    const [data, setData] = useState<GetInventoryCommandOutput | undefined>(defaultValue);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error>({ hasError: false });

    // Client
    const [client] = getTinventClient();

    function doGetInventory(props: InventoryProps) {
        setLoading(true);

        const request: GetInventoryCommandInput = {
            domain: "software",
            pageSize: props.pageSize,
            nextToken: (props.pageIndex - 1).toString(),
            filters: props.filters,
        };

        if (props.clearPreviousData && props.clearPreviousData === true) {
            setData(undefined);
        }

        client.getInventory(request, (err: any, response?: GetInventoryCommandOutput | undefined) => {
            if (!response || !response.items) {
                const errorMessage = "Error getting inventory items: " + err;
                console.error(errorMessage);
                setLoading(false);
                setError({
                    hasError: true,
                    message: errorMessage,
                } as Error);
            }
            setData(response);
            setLoading(false);
        });
    }

    useEffect(() => {
        if (!defaultValue) {
            doGetInventory(mainProps);
        }
    }, [defaultValue]);

    return [data, isLoading, error, doGetInventory];
}

export function useGetFiles(
    mainProps: InventoryProps,
    defaultValue: GetFilesCommandOutput | undefined,
): [GetFilesCommandOutput | undefined, boolean, Error, (props: InventoryProps) => void] {
    const [data, setData] = useState<GetFilesCommandOutput | undefined>(defaultValue);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error>({ hasError: false });

    // Client
    const [client] = getTinventClient();

    function doGetFiles(props: InventoryProps) {
        if (!props.fromAutoRefresh) {
            setLoading(true);
        }

        const request: GetFilesCommandInput = {
            pageSize: props.pageSize,
            nextToken: (props.pageIndex - 1).toString(),
        };

        client.getFiles(request, (err: any, response?: GetFilesCommandOutput | undefined) => {
            if (!response || !response.files) {
                const errorMessage = "Error getting files: " + err;
                console.error(errorMessage);
                setLoading(false);
                setError({
                    hasError: true,
                    message: errorMessage,
                } as Error);
            }
            setData(response);
            setLoading(false);
        });
    }

    useEffect(() => {
        if (!defaultValue) {
            doGetFiles(mainProps);
        }
    }, [defaultValue]);

    return [data, isLoading, error, doGetFiles];
}

export function useGetLicenseDetails(
    mainProps: GetLicenseDetailsProps,
    defaultValue: GetLicenseDetailsCommandOutput | undefined,
): [GetLicenseDetailsCommandOutput | undefined, boolean, Error, (props: GetLicenseDetailsProps) => void] {
    const [data, setData] = useState<GetLicenseDetailsCommandOutput | undefined>(defaultValue);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error>({ hasError: false });

    // Client
    const [client] = getTinventClient();

    function doGetLicenseDetails(props: GetLicenseDetailsProps) {
        setLoading(true);
        const request: GetLicenseDetailsCommandInput = {
            taxonomyId: props.productTaxonomyId,
            assigned: props.assigned ?? "false",
            pageSize: props.pageSize,
            nextToken: (props.pageIndex - 1).toString(),
            filters: props.filters,
        };

        client.getLicenseDetails(request, (err: any, response?: GetLicenseDetailsCommandOutput | undefined) => {
            if (!response || !response.items) {
                const errorMessage = `Error getting license details for: ${props.productTaxonomyId}. Error: ${err}`;
                console.error(errorMessage);
                setLoading(false);
                setError({
                    hasError: true,
                    message: errorMessage,
                } as Error);
            }
            setData(response);
            setLoading(false);
        });
    }

    useEffect(() => {
        if (!defaultValue) {
            doGetLicenseDetails(mainProps);
        }
    }, [defaultValue]);

    return [data, isLoading, error, doGetLicenseDetails];
}

export function useDeactivateLicenses(
    mainProps: DeactivateLicensesProps,
    defaultValue: DeactivateLicensesCommandOutput | undefined = undefined,
): [DeactivateLicensesCommandOutput | undefined, boolean, Error, (taxonomyIds: string[], csrfToken: string) => void] {
    const [data, setData] = useState<DeactivateLicensesCommandOutput | undefined>(defaultValue);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error>({ hasError: false });

    // Client
    const [client, requestHandler] = getTinventClient();

    function doDeactivateLicenses(taxonomyIds: string[], csrfToken: string) {
        setLoading(true);
        requestHandler.pushHeader("x-csrf-token", csrfToken);

        const request: DeactivateLicensesCommandInput = {
            taxonomyIds: mainProps.taxonomyIds,
        };

        client.deactivateLicenses(request, (err: any, response?: DeactivateLicensesCommandOutput | undefined) => {
            if (!response || !response.rowCount) {
                const errorMessage = `Error deactivating the licenses selected. Error: ${err}`;
                console.error(errorMessage);
                setLoading(false);
                setError({
                    hasError: true,
                    message: errorMessage,
                } as Error);
            }
            setData(response);
            setLoading(false);
        });
    }
    return [data, isLoading, error, doDeactivateLicenses];
}

function getTinventClient(): [TInvent, SentryFetchHttpHandler] {
    const requestHandler = new SentryFetchHttpHandler();
    return [
        new TInvent({
            endpoint: `${API_BASE_URL}/tinvent`,
            region: "*",
            credentials: { accessKeyId: "", secretAccessKey: "" },
            requestHandler,
        }),
        requestHandler,
    ];
}
