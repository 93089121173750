/* istanbul ignore file */
import { Order } from "@amzn/ito-client";
import { useSubmitOrder } from "hooks";
import { useState } from "react";
import { CSRFService } from "services/csrf-service";

import { useCreateOrderForm, useCreateOrderFormResult } from "./useCreateOrderForm";

// Modal data
export interface useCreateOrderModalProps {
    /**
     *
     * @returns
     */
    onCloseModal: () => void;
}

export interface useCreateOrderModalActionsResult {
    /**
     * Action to use when submitting an order
     * @returns
     */
    submitOrder: () => void;
    /**
     *
     * @returns
     */
    closeModal: () => void;
    /**
     * Action to go back to the form state
     * @returns
     */
    goBackToForm: () => void;
}

export interface useCreateOrderModalStateResult {
    isLoading: boolean;
    orderPreview: Order | undefined;
    orderSubmitted: Order | undefined;
    error: string;
}

export interface useCreateOrderModalResult {
    actions: useCreateOrderModalActionsResult;
    state: useCreateOrderModalStateResult;
    useForm: useCreateOrderFormResult;
}

export function useCreateOrderModal({ onCloseModal }: useCreateOrderModalProps): useCreateOrderModalResult {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [orderPreview, setOrderPreview] = useState<Order>();
    const [orderSubmitted, setOrderSubmitted] = useState<Order>();
    const [error, setError] = useState<string>("");

    const [itemId, setItemId] = useState<string>("");
    const [requester, setRequester] = useState<string>("");

    const { doSubmitOrder: doSubmitDryRunOrder } = useSubmitOrder({
        dataStateOverride: [orderPreview, setOrderPreview],
        loadingStateOverride: [isLoading, setIsLoading],
        errorStateOverride: [error, setError],
    });

    const { doSubmitOrder } = useSubmitOrder({
        dataStateOverride: [orderSubmitted, setOrderSubmitted],
        loadingStateOverride: [isLoading, setIsLoading],
        errorStateOverride: [error, setError],
    });

    // CSRF Token
    const service = new CSRFService();

    const goBackToForm = () => {
        setOrderPreview(undefined);
        setOrderSubmitted(undefined);
        setError("");
    };

    const submitPreviewOrder = async (itemId: string, requester: string) => {
        setIsLoading(true);
        setItemId(itemId);
        setRequester(requester);
        setError("");

        const csrfToken = await service.getToken();

        doSubmitDryRunOrder(
            {
                items: { [itemId]: { quantity: 1 } },
                dryRun: true,
                requesterOverride: requester,
            },
            csrfToken,
        );
    };

    const submitOrder = async () => {
        setIsLoading(true);
        const csrfToken = await service.getToken();

        doSubmitOrder(
            {
                items: { [itemId]: { quantity: 1 } },
                dryRun: false,
                requesterOverride: requester,
            },
            csrfToken,
        );
    };

    const useForm = useCreateOrderForm({ submitPreviewOrder });

    return {
        useForm,
        actions: {
            closeModal: onCloseModal,
            submitOrder,
            goBackToForm,
        },
        state: {
            isLoading,
            orderPreview,
            orderSubmitted,
            error,
        },
    };
}
