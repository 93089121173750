import { Box, ColumnLayout, Container, FormField, Header, SpaceBetween } from "@amzn/awsui-components-react";
import CheckboxGroupItem from "common/CheckboxGroup/CheckboxGroupItem";
import { getString } from "common/ui-string-labels/ui-strings-utils";
import { CatalogItemValues } from "interfaces/catalogSchema";
import { useState } from "react";

import CheckboxGroup from "../../../common/CheckboxGroup/CheckboxGroup";
import regionsData from "./regions.json";

interface AvailableRegionsFormProps {
    handleInputChange: (field: keyof CatalogItemValues, value: string[]) => void;
    selectedRegions: string[];
}

const AvailableRegionsForm = ({ handleInputChange, selectedRegions }: AvailableRegionsFormProps) => {
    const allRegions = [
        ...regionsData.regionsEMEA,
        ...regionsData.regionsAmericas,
        ...regionsData.regionsAPAC,
        ...regionsData.regionsVirtual,
    ];

    const [isSelectAllChecked, setIsSelectAllChecked] = useState(selectedRegions.length === allRegions.length);

    /* istanbul ignore next */
    const handleRegionChange = (newRegions: string[]) => {
        handleInputChange("region_restrictions", newRegions);
        setIsSelectAllChecked(newRegions.length === allRegions.length);
    };

    /* istanbul ignore next */
    const handleSelectAllChange = () => {
        if (isSelectAllChecked) {
            handleInputChange("region_restrictions", []);
            setIsSelectAllChecked(false);
        } else {
            handleInputChange("region_restrictions", allRegions);
            setIsSelectAllChecked(true);
        }
    };
    return (
        <Container
            data-testid="available-regions-form"
            header={<Header variant="h2">{getString("createCatalog.formAvailableRegions.formHeader")}</Header>}
        >
            <ColumnLayout borders="horizontal" columns={5} minColumnWidth={1}>
                <CheckboxGroup
                    label={getString("createCatalog.formAvailableRegions.regionEMEA")}
                    items={regionsData.regionsEMEA}
                    selectAllLabel={getString("createCatalog.formAvailableRegions.regionEMEA")}
                    selectedRegions={selectedRegions}
                    handleRegionChange={handleRegionChange}
                />
                <CheckboxGroup
                    label={getString("createCatalog.formAvailableRegions.regionAmericas")}
                    items={regionsData.regionsAmericas}
                    selectAllLabel={getString("createCatalog.formAvailableRegions.regionAmericas")}
                    selectedRegions={selectedRegions}
                    handleRegionChange={handleRegionChange}
                />
                <CheckboxGroup
                    label={getString("createCatalog.formAvailableRegions.regionAPAC")}
                    items={regionsData.regionsAPAC}
                    selectAllLabel={getString("createCatalog.formAvailableRegions.regionAPAC")}
                    selectedRegions={selectedRegions}
                    handleRegionChange={handleRegionChange}
                />
                <CheckboxGroup
                    label={getString("createCatalog.formAvailableRegions.regionVirtual")}
                    items={regionsData.regionsVirtual}
                    selectAllLabel={getString("createCatalog.formAvailableRegions.regionVirtual")}
                    selectedRegions={selectedRegions}
                    handleRegionChange={handleRegionChange}
                />
                <FormField data-testid="formfield-test" stretch>
                    <SpaceBetween size="xs">
                        <ColumnLayout borders="horizontal" columns={1}>
                            <CheckboxGroupItem
                                key="select-all"
                                id="select-all"
                                label={
                                    <strong>{getString("createCatalog.formAvailableRegions.selectAllRegions")}</strong>
                                }
                                checked={isSelectAllChecked}
                                onChange={handleSelectAllChange}
                            />
                            <Box />
                        </ColumnLayout>
                    </SpaceBetween>
                </FormField>
            </ColumnLayout>
        </Container>
    );
};

export default AvailableRegionsForm;
