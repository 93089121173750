import { Flashbar } from "@amzn/awsui-components-react";
import AppLayout from "@amzn/awsui-components-react/polaris/app-layout";
import { useFlashBarItems } from "common/UseFlashBarItems/useFlashBarItems";
import { useState } from "react";

import NavigationBreadcrumbs from "../../common/navigation/NavigationBreadcrumbs";
import SideNavigationMenu from "../../common/navigation/SideNavigationMenu";
import Paths from "../../common/paths/paths";
import ManageLicenseTable from "../../components/ManageLicenseTable/ManageLicenseTable";

const breadcrumbs = {
    items: [
        { text: Paths.Admin.name, href: Paths.Admin.url },
        { text: Paths.manageLicense.name, href: Paths.manageLicense.url },
    ],
};

const ManageLicensePage = () => {
    const [navigationOpen, setNavigationOpen] = useState(false);
    const flashBarItemsHook = useFlashBarItems();

    return (
        <AppLayout
            content={<ManageLicenseTable useFlashBarItemsActions={flashBarItemsHook.actions} />}
            breadcrumbs={<NavigationBreadcrumbs items={breadcrumbs.items} />}
            navigation={<SideNavigationMenu />}
            navigationOpen={navigationOpen}
            notifications={<Flashbar items={flashBarItemsHook.flashBarItems} stackItems />}
            onNavigationChange={(event: any) => setNavigationOpen(event.detail.open)}
        />
    );
};

export default ManageLicensePage;
