import {
  Box,
  Button,
  FlashbarProps,
  FormField,
  Header,
  Modal,
  SpaceBetween,
  Textarea
} from '@amzn/awsui-components-react';
import { useEffect, useState } from 'react';
import { getString } from '../../../common/ui-string-labels/ui-strings-utils';
import { Order, OrderStatus } from '@amzn/ito-client';
import { usePostComment, useUpdateOrdersStatus } from '../../../hooks';
import { useFlashBarItemsActions } from 'common/UseFlashBarItems/useFlashBarItems';
import { CSRFService } from 'services/csrf-service';

export interface CancelOrderModalProps {
  selectedItem: Order;
  showModal: boolean;
  onDismiss: (shouldRefreshTable?: boolean) => void;
  flashBarItemsActions: useFlashBarItemsActions;
}

export const CancelOrderModal = (props: CancelOrderModalProps) => {
  const { selectedItem, flashBarItemsActions, onDismiss } = props;
  const { isLoading, data, doUpdateOrders, error } = useUpdateOrdersStatus();
  const [postCommentResponse, isPostCommentProcessing, commentError, doPostComment] = usePostComment();
  const [cancelReason, setCancelReason] = useState('');

  /* istanbul ignore next */
  const cancelOrder = () => {
    // Fetch CSRF token
    const csrfService = new CSRFService();
    csrfService.getToken().then((token: string) => {
      doUpdateOrders([selectedItem], { status: OrderStatus.CANCELLED }, token);
      doPostComment(selectedItem.orderId!, cancelReason, token);
    });
  };

  /* istanbul ignore next */
  const dismissModal = () => {
    onDismiss(false);
  };

  /* istanbul ignore next */
  const onChange = ({ detail }: any) => {
    setCancelReason(detail.value);
  };

  /* istanbul ignore next */
  useEffect(() => {
    if (data && postCommentResponse) {
      onDismiss(true);
      // all true
      if (error) {
        flashBarItemsActions.addErrorFlashBarItem(`${getString('manageOrders.cancelModal.error')}. ${error.message}`);
      } else if (commentError) {
        flashBarItemsActions.addErrorFlashBarItem(
          `${getString('manageOrders.cancelModal.error')}. ${commentError.message}`
        );
      } else {
        flashBarItemsActions.addSuccessFlashBarItem(getString('manageOrders.cancelModal.success'));
      }
    }
  }, [data, postCommentResponse]);

  const footerElement = (
    <SpaceBetween direction="horizontal" size="xs">
      <Button variant="link" onClick={dismissModal} disabled={isLoading || isPostCommentProcessing}>
        {getString('manageOrders.cancelModal.cancelButton')}
      </Button>
      <Button
        variant="primary"
        loading={isLoading || isPostCommentProcessing}
        data-cy="cancel-order-action"
        onClick={cancelOrder}
        disabled={cancelReason.trim() === ''}
      >
        {getString('manageOrders.cancelModal.okButton')}
      </Button>
    </SpaceBetween>
  );

  return (
    <Modal
      data-testid="cancel-order-modal"
      onDismiss={dismissModal}
      visible={props.showModal}
      header={<Header variant="h2">{getString('manageOrders.cancelModal.modalTitle')}</Header>}
      footer={<Box float="right">{footerElement}</Box>}
    >
      <FormField label={getString('manageOrders.cancelModal.inputLabel')}>
        <Textarea onChange={onChange} value={cancelReason} />
      </FormField>
    </Modal>
  );
};
