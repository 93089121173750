import AppLayout from "@amzn/awsui-components-react/polaris/app-layout";
import UploadOrdersTable from "components/UploadOrdersTable/UploadOrdersTable";
import { useState } from "react";

import NavigationBreadcrumbs from "../../common/navigation/NavigationBreadcrumbs";
import SideNavigationMenu from "../../common/navigation/SideNavigationMenu";
import Paths from "../../common/paths/paths";

const breadcrumbs = {
    items: [
        { text: Paths.Admin.name, href: Paths.Admin.url },
        { text: Paths.uploadOrders.name, href: Paths.uploadOrders.url },
    ],
};

const UploadOrdersPage = () => {
    const [navigationOpen, setNavigationOpen] = useState(false);

    return (
        <AppLayout
            content={<UploadOrdersTable />}
            breadcrumbs={<NavigationBreadcrumbs items={breadcrumbs.items} />}
            navigation={<SideNavigationMenu />}
            navigationOpen={navigationOpen}
            onNavigationChange={(event: any) => setNavigationOpen(event.detail.open)}
        />
    );
};

export default UploadOrdersPage;
