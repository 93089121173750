import { Button, Header, SpaceBetween, Spinner } from "@amzn/awsui-components-react";
import { LicenseDetail } from "@amzn/tinvent-typescript-client";
import { getString } from "common/ui-string-labels/ui-strings-utils";
import { useFlashBarItemsActions } from "common/UseFlashBarItems/useFlashBarItems";
import { PROD_DOMAIN } from "configuration/config";
import { useDeactivateLicenses } from "hooks/tinvent";
import { useEffect } from "react";
import { CSRFService } from "services/csrf-service";

interface LicenseDetailsTableHeaderProps {
    productName: string | undefined;
    totalResults: number;
    selectedItems: LicenseDetail[];
    onDeactivateLicenses: () => Promise<void>;
    flashBarItemsActions: useFlashBarItemsActions;
}

export const LicenseDetailsTableHeader = (props: LicenseDetailsTableHeaderProps) => {
    const { productName, totalResults, selectedItems, onDeactivateLicenses, flashBarItemsActions } = props;
    const licensesTaxonomyIds: string[] = selectedItems.map((i) => i.licenseTaxonomyId!);

    // Send licenses to be deactivated on TInvent
    const [data, isLoading, error, doDeactivateLicenses] = useDeactivateLicenses({ taxonomyIds: licensesTaxonomyIds });

    const deactivateLicenses = async () => {
        // Fetch CSRF token
        const csrfService = new CSRFService();
        const token = await csrfService.getToken();

        // Call doDeactivateLicenses to deactivate the licenses
        await doDeactivateLicenses(licensesTaxonomyIds, token);
    };

    // Display the sucess banner
    useEffect(() => {
        if (data && data.rowCount && data.rowCount > 0) {
            flashBarItemsActions.addSuccessFlashBarItem(
                `${getString("licenseDetails.table.deactivateLicenses.success")} ${data?.rowCount}`,
            );
        }
        onDeactivateLicenses();
    }, [data]);

    // Display the error banner
    useEffect(() => {
        if (error && error.hasError) {
            flashBarItemsActions.addErrorFlashBarItem(`${getString("licenseDetails.table.deactivateLicenses.error")}.`);
        }
        onDeactivateLicenses();
    }, [error]);

    return (
        <SpaceBetween size="m">
            <Header
                data-testid="license-details-table-header"
                variant="awsui-h1-sticky"
                counter={`(${totalResults})`}
                actions={
                    <SpaceBetween direction="horizontal" size="xs">
                        {isLoading ? (
                            <Spinner size="large" />
                        ) : (
                            <Button
                                data-testid="deactivate-licenses-button"
                                variant="primary"
                                onClick={deactivateLicenses}
                                disabled={selectedItems.length === 0}
                            >
                                {getString("licenseDetails.table.deactivateLicenseButton")}
                            </Button>
                        )}
                    </SpaceBetween>
                }
            >
                {productName}
            </Header>
        </SpaceBetween>
    );
};

export default LicenseDetailsTableHeader;
