/* istanbul ignore file */

import "react-quill/dist/quill.snow.css";

import React from "react";
import ReactQuill from "react-quill";

interface CustomReactQuillProps {
    value: string;
    onChange: (value: string) => void;
}

const modules = {
    toolbar: [
        [{ font: [] }],
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        [{ header: "1" }, { header: "2" }, "blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link"],
        ["clean"],
    ],
};

const formats = [
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "color",
    "background",
    "header",
    "blockquote",
    "code-block",
    "list",
    "bullet",
    "link",
];

const HtmlEditor: React.FC<CustomReactQuillProps> = ({ value, onChange }) => {
    return (
        <ReactQuill
            data-testid="html-editor"
            modules={modules}
            formats={formats}
            theme="snow"
            value={value}
            onChange={onChange}
        />
    );
};

export default HtmlEditor;
