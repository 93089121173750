import { Flashbar } from "@amzn/awsui-components-react";
import AppLayout from "@amzn/awsui-components-react/polaris/app-layout";
import OrderDetailsSidePanel from "components/ManageOrders/OrderSidePanel";
import { useDetailsSidePanel, useManageOrdersTable } from "components/ManageOrders/useManageOrdersTable";
import { useState } from "react";
import { useLocation } from "react-router-dom";

import NavigationBreadcrumbs from "../../common/navigation/NavigationBreadcrumbs";
import SideNavigationMenu from "../../common/navigation/SideNavigationMenu";
import Paths from "../../common/paths/paths";
import { useFlashBarItems } from "../../common/UseFlashBarItems/useFlashBarItems";
import { ManageOrdersTable } from "../../components/ManageOrders";

const OrdersUploadedPage = () => {
    const location = useLocation();
    let fileGuid = "";
    let fileName = "";
    if (location.state) {
        fileGuid = location.state.fileGuid;
        fileName = location.state.fileName;
    }

    const breadcrumbs = {
        items: [
            { text: Paths.Admin.name, href: Paths.uploadLicense.url },
            { text: Paths.manageOrders.name, href: Paths.manageOrders.url },
            { text: fileName, href: "#" },
        ],
    };

    const [navigationOpen, setNavigationOpen] = useState(false);
    const flashBarItemsHook = useFlashBarItems();
    const detailsSidePanelHook = useDetailsSidePanel();
    const useManageOrdersTableHook = useManageOrdersTable();

    const onDetailsSidePanelChange = (open: React.SetStateAction<boolean>) => {
        if (!open) {
            useManageOrdersTableHook.actions.refreshAttributes();
        }
    };

    return (
        <AppLayout
            content={
                <ManageOrdersTable
                    useFlashBarItemsActions={flashBarItemsHook.actions}
                    useDetailsSidePanel={detailsSidePanelHook}
                    useManageOrdersTableHook={useManageOrdersTableHook}
                    fileId={fileGuid}
                    fileName={fileName}
                />
            }
            breadcrumbs={<NavigationBreadcrumbs items={breadcrumbs.items} />}
            navigation={<SideNavigationMenu />}
            navigationOpen={navigationOpen}
            tools={<OrderDetailsSidePanel selectedOrder={detailsSidePanelHook.selectedOrder} />}
            toolsOpen={detailsSidePanelHook.isOpened}
            onToolsChange={(event: any) => onDetailsSidePanelChange(event.detail.open)}
            toolsHide={!detailsSidePanelHook.isOpened}
            notifications={<Flashbar items={flashBarItemsHook.flashBarItems} stackItems />}
            onNavigationChange={(event: any) => setNavigationOpen(event.detail.open)}
        />
    );
};

export default OrdersUploadedPage;
