import {
    Box,
    ColumnLayout,
    Container,
    FileUpload,
    FormField,
    Grid,
    Header,
    Input,
    Multiselect,
    SpaceBetween,
} from "@amzn/awsui-components-react";
import FormFieldWithSelect from "common/FormFieldWithSelect/FormFieldWithSelect";
import HtmlEditor from "common/HtmlEditor/HtmlEditor";
import { getString } from "common/ui-string-labels/ui-strings-utils";
import { CatalogItemValues } from "interfaces/catalogSchema";
import { useState } from "react";

interface ProductInformationFormProps {
    catalogItemValues: CatalogItemValues;
    handleInputChange: (field: keyof CatalogItemValues, value: string | string[]) => void;
    errors: {
        nameError: string;
        amountError: string;
        statusError: string;
        currencyError: string;
    };
}

const currencyOptions = [
    { label: "USD", value: "USD" },
    { label: "EUR", value: "EUR" },
    { label: "JPY", value: "JPY" },
    { label: "CRI", value: "CRI" },
];
const statusOptions = [
    { label: "Sanctioned", value: "sanctioned" },
    { label: "Unsanctioned", value: "unsanctioned" },
    { label: "Unavailable", value: "unavailable" },
];
const osOptions = [
    { label: "Windows", value: "Windows" },
    { label: "Mac", value: "Mac" },
    { label: "Linux", value: "Linux" },
];

const ProductInformationForm = ({ catalogItemValues, handleInputChange, errors }: ProductInformationFormProps) => {
    const [valueFileUpload] = useState([]);

    const [selectedOsOptions, setSelectedOsOptions] = useState(
        catalogItemValues.os?.map((os) => ({ label: os, value: os })) || [],
    );

    /* istanbul ignore next */
    return (
        <Container
            data-testid="product-information-form"
            header={<Header variant="h2">{getString("createCatalog.formProductInformation.formHeader")}</Header>}
        >
            <SpaceBetween size="m">
                {/* Parent grid: separates 6 form fields in the first 10 columns, and in the other 2 columns separates the file upload field.
                    For the 6 columns in the first 10 columns of the parent grid, it uses column layout to space out 3 columns in two rows. 
                    Finally, it puts them both inside a div, which the parent div detects to place them both in the first 10 columns.  */}

                <Grid gridDefinition={[{ colspan: 10 }, { colspan: 2 }]}>
                    <Box>
                        <ColumnLayout columns={3}>
                            <FormField
                                errorText={errors.nameError}
                                data-testid="formfield-test"
                                label={getString("createCatalog.formProductInformation.inputProductName")}
                            >
                                <Input
                                    data-cy="product-name-input"
                                    ariaRequired
                                    onChange={({ detail }) => handleInputChange("name", detail.value)}
                                    value={catalogItemValues.name}
                                />
                            </FormField>
                            <FormField
                                errorText={errors.amountError}
                                data-testid="formfield-test"
                                label={getString("createCatalog.formProductInformation.inputCost")}
                            >
                                <Input
                                    data-cy="product-amount-input"
                                    ariaRequired
                                    onChange={({ detail }) => handleInputChange("amount", detail.value)}
                                    value={catalogItemValues.amount}
                                />
                            </FormField>

                            <FormFieldWithSelect
                                dataCy="product-currency-select"
                                ariaRequired
                                selectedValue={catalogItemValues.currency}
                                error={errors.currencyError}
                                label={getString("createCatalog.formProductInformation.inputCurrency")}
                                options={currencyOptions}
                                onSelectionChange={(value) => handleInputChange("currency", value)}
                            />
                        </ColumnLayout>
                        <ColumnLayout columns={3}>
                            <FormFieldWithSelect
                                ariaRequired
                                error={errors.statusError}
                                dataCy="product-status-select"
                                selectedValue={catalogItemValues.status}
                                label={getString("createCatalog.formProductInformation.inputStatus")}
                                options={statusOptions}
                                onSelectionChange={(value) => handleInputChange("status", value)}
                            />
                            <FormField
                                data-testid="formfield-test"
                                label={getString("createCatalog.formProductInformation.inputOperatingSystem")}
                            >
                                <Multiselect
                                    selectedOptions={selectedOsOptions}
                                    onChange={({ detail }) => {
                                        const validOptions = detail.selectedOptions.filter(
                                            (option): option is { label: string; value: string } =>
                                                option.label !== undefined && option.value !== undefined,
                                        );
                                        setSelectedOsOptions(validOptions);
                                        const osValues = validOptions.map((option) => option.value);
                                        handleInputChange("os", osValues);
                                    }}
                                    hideTokens
                                    options={osOptions}
                                    placeholder={
                                        selectedOsOptions.length > 0
                                            ? selectedOsOptions.map((option) => option.label).join(", ")
                                            : ""
                                    }
                                />
                            </FormField>
                            <FormField
                                data-testid="formfield-test"
                                label={
                                    <span>
                                        {getString("createCatalog.formProductInformation.inputOrderUrl")}
                                        <i>{getString("createCatalog.formLabels.italicOptional")}</i>
                                    </span>
                                }
                            >
                                <Input
                                    onChange={({ detail }) => handleInputChange("order_redirect_url", detail.value)}
                                    value={catalogItemValues.order_redirect_url}
                                />
                            </FormField>
                        </ColumnLayout>
                    </Box>
                    <Box>
                        <FormField
                            data-testid="formfield-test"
                            label={getString("createCatalog.formProductInformation.inputLogoTitle")}
                        >
                            {/* istanbul ignore next */}
                            <FileUpload
                                value={valueFileUpload}
                                i18nStrings={{
                                    uploadButtonText: (e) => (e ? "Choose files" : "Choose file"),
                                    dropzoneText: (e) => (e ? "Drop files to upload" : "Drop file to upload"),
                                    removeFileAriaLabel: (e) => `Remove file ${e + 1}`,
                                    limitShowFewer: "Show fewer files",
                                    limitShowMore: "Show more files",
                                    errorIconAriaLabel: "Error",
                                }}
                                showFileLastModified
                                showFileSize
                                showFileThumbnail
                                tokenLimit={3}
                            />
                        </FormField>
                    </Box>
                </Grid>
                <FormField
                    data-testid="formfield-test"
                    stretch
                    label={getString("createCatalog.formProductInformation.inputSummaryTitle")}
                    description={getString("createCatalog.formProductInformation.inputSummaryText")}
                >
                    <Input
                        onChange={({ detail }) => handleInputChange("short_description", detail.value)}
                        value={catalogItemValues.short_description}
                    />
                </FormField>
                <FormField
                    data-testid="formfield-test"
                    stretch
                    label={getString("createCatalog.formProductInformation.inputProductDescriptionTitle")}
                    description={getString("createCatalog.formProductInformation.inputProductDescriptionText")}
                >
                    <HtmlEditor
                        value={catalogItemValues.description}
                        onChange={(value) => handleInputChange("description", value)}
                    />
                </FormField>
            </SpaceBetween>
        </Container>
    );
};
export default ProductInformationForm;
