import {
    Alert,
    Box,
    ButtonDropdown,
    CollectionPreferences,
    Grid,
    Header,
    Pagination,
    Select,
    SpaceBetween,
    Table,
} from "@amzn/awsui-components-react";
import PropertyFilter from "@amzn/awsui-components-react/polaris/property-filter";
import { ConfirmActionModal } from "common/ConfirmActionModal/ConfirmActionModal";
import { useFlashBarItemsActions } from "common/UseFlashBarItems/useFlashBarItems";

import EmptyTableBox from "../../common/EmptyTableBox/EmptyTableBox";
import InternalLink from "../../common/navigation/InternalLink";
import { getPropertyFilterString, getString } from "../../common/ui-string-labels/ui-strings-utils";
import { defaultPagingOptions } from "../../common/utils/table-utils";
import { UpdateInventoryNoteModal } from "./UpdateInventoryNoteModal/UpdateInventoryNoteModal";
import { useManageLicenseTable } from "./useManageLicenseTable";

export interface ManageLicenseTableProps {
    useFlashBarItemsActions?: useFlashBarItemsActions;
}

const ManageLicenseTable = (props: ManageLicenseTableProps) => {
    const { attributes, actions } = useManageLicenseTable({ flashBarItemsActions: props.useFlashBarItemsActions! });

    return (
        <Box data-testid="manage-license-box">
            {attributes.error.hasError && (
                <Alert
                    data-testid="manage-license-error-alert"
                    statusIconAriaLabel="Error"
                    type="error"
                    header={getString("manageLicense.table.errorHeader")}
                >
                    {attributes.error.message}
                </Alert>
            )}
            <Table
                {...attributes.collectionProps}
                data-testid="manage-license-table"
                header={
                    <SpaceBetween size="m">
                        <Header
                            variant="awsui-h1-sticky"
                            counter={`(${attributes.totalResults})`}
                            actions={
                                <ButtonDropdown
                                    items={attributes.dropdownValues.actionOptions}
                                    onItemClick={actions.tableActions.onItemClick}
                                    expandToViewport
                                >
                                    {getString("common.actions")}
                                </ButtonDropdown>
                            }
                        >
                            {getString("manageLicense.table.headerTitle")}
                            {attributes.state.updateInventoryNoteModalVisible && (
                                <UpdateInventoryNoteModal
                                    showModal={attributes.state.updateInventoryNoteModalVisible}
                                    onDismiss={actions.modal.dismissUpdateNoteModal}
                                    flashBarItemsActions={props.useFlashBarItemsActions!}
                                    selectedItems={attributes.state.selectedItems}
                                />
                            )}
                            {attributes.state.deleteNoteModalVisible && (
                                <ConfirmActionModal
                                    visible={attributes.state.deleteNoteModalVisible}
                                    onDismiss={actions.modal.dismissDeleteNoteModal}
                                    onAction={actions.modal.onDeleteNote}
                                    warningText={getString("manageLicense.table.deleteNoteConfirmation", {
                                        itemCount: attributes.state.selectedItems.length,
                                    })}
                                    modalTitle={getString("manageLicense.table.actions.deleteNote")}
                                    isProcessing={attributes.state.isProcessing}
                                />
                            )}
                        </Header>
                        <Box color="text-status-inactive">
                            {getString("manageLicense.table.headerDescription")}
                            <InternalLink href="/uploadLicense">
                                {getString("uploadLicense.table.headerTitle")}
                            </InternalLink>
                        </Box>
                    </SpaceBetween>
                }
                filter={
                    <Grid gridDefinition={[{ colspan: 8 }, { colspan: 2 }, { colspan: 2 }]}>
                        <PropertyFilter
                            query={attributes.state.currentFilteringQuery}
                            onChange={actions.filtering.onPropertyFilterChange}
                            filteringProperties={attributes.filtering.properties}
                            i18nStrings={getPropertyFilterString()}
                            hideOperations={true}
                        />
                        <Select
                            data-testid="select-low-inventory-flag"
                            selectedOption={attributes.state.selectedRestockFlag}
                            onChange={actions.filtering.onSelectedRestockFlagChange}
                            options={attributes.dropdownValues.inventoryFlagOptions}
                            selectedAriaLabel="Selected"
                        />
                    </Grid>
                }
                pagination={
                    <Pagination
                        currentPageIndex={attributes.state.currentPageIndex.index}
                        pagesCount={attributes.state.pagesCount}
                        onChange={actions.pagination.onPageClick}
                        ariaLabels={{
                            nextPageLabel: getString("common.pagination.nextPage"),
                            previousPageLabel: getString("common.pagination.nextPage"),
                            pageLabel: (pageNumber: number) =>
                                getString("common.pagination.currentPage", {
                                    pageNumber,
                                    pagesCount: attributes.state.pagesCount,
                                }),
                        }}
                    />
                }
                onSortingChange={actions.sorting.onSortingChange}
                empty={<EmptyTableBox />}
                stripedRows={attributes.currentPreferences.stripedRows}
                preferences={
                    <CollectionPreferences
                        title={getString("common.preferences.title")}
                        confirmLabel={getString("common.preferences.confirmLabel")}
                        cancelLabel={getString("common.preferences.cancelLabel")}
                        pageSizePreference={{
                            title: getString("common.preferences.pageSize.title"),
                            options: defaultPagingOptions,
                        }}
                        stripedRowsPreference={{
                            label: getString("common.preferences.stripedRows.label"),
                            description: getString("common.preferences.stripedRows.description"),
                        }}
                        visibleContentPreference={{
                            title: getString("common.preferences.visibleContentPreference.title"),
                            options: [
                                {
                                    label: getString("common.preferences.visibleContentPreference.columns"),
                                    options: attributes.tablePreferences.visibleContentOptions,
                                },
                            ],
                        }}
                        preferences={attributes.currentPreferences}
                        onConfirm={actions.preferences.onPreferencesConfirmHandler}
                    />
                }
                columnDefinitions={attributes.currentColumnDefinitions}
                loadingText={getString("common.loading")}
                loading={attributes.state.isLoading}
                items={attributes.inventoryData?.items}
                resizableColumns={true}
                selectionType="multi"
                onSelectionChange={actions.selection.onSelectionChangeHandler}
                selectedItems={attributes.state.selectedItems}
                variant="full-page"
                wrapLines
            />
        </Box>
    );
};

export default ManageLicenseTable;
