import SideNavigation, { SideNavigationProps } from "@amzn/awsui-components-react/polaris/side-navigation";
import { ITMPV1_WEBSITE_URL, PERMISSIONS } from "configuration/config";
import { useLocation, useNavigate } from "react-router-dom";
import { UserPermissions } from "services/user-permissions";

import Paths from "../paths/paths";

const OrderDashboardURL =
    "https://metrics-it.corp.amazon.com/#/site/Global-IT/views/ITMPOrderStatus/ITMPsoftwareorderstatus";

const header = { text: "ITMP admin", href: Paths.Admin.url };

const SideNavigationMenu = () => {
    const location = useLocation();
    const navigate = useNavigate();

    /**
     * Prevents reloading the page and lets react router handle the redirect
     * @param event
     */
    const onFollowHandler = (event: CustomEvent<SideNavigationProps.FollowDetail>) => {
        if (!event.detail.external) {
            event.preventDefault();
            if (event.detail.href) {
                navigate(event.detail.href);
            }
        }
    };

    const items: SideNavigationProps.Item[] = [
        {
            type: "section",
            text: "Orders",
            items: [
                { type: "link", text: Paths.manageOrders.name, href: Paths.manageOrders.url },
                { type: "link", text: Paths.uploadOrders.name, href: Paths.uploadOrders.url },
                { type: "link", text: Paths.ordersDownloaded.name, href: Paths.ordersDownloaded.url },
                { type: "link", text: "View order dashboard", href: OrderDashboardURL, external: true },
            ],
        },
        {
            type: "section",
            text: "Licenses",
            items: [
                { type: "link", text: Paths.manageLicense.name, href: Paths.manageLicense.url },
                { type: "link", text: Paths.uploadLicense.name, href: Paths.uploadLicense.url },
            ],
        },
        {
            type: "section",
            text: "Catalog",
            items: [
                UserPermissions.instance.hasPermission(PERMISSIONS.USE_CATALOGICAL)
                    ? { type: "link", text: Paths.manageCatalog.name, href: Paths.manageCatalog.url }
                    : {
                          type: "link",
                          text: "Manage catalog",
                          href: new URL("/admin/catalog_items", ITMPV1_WEBSITE_URL).href,
                          external: true,
                      },
                {
                    type: "link",
                    text: "Manage product categories",
                    href: new URL("admin/categories", ITMPV1_WEBSITE_URL).href,
                    external: true,
                },
            ],
        },
        { type: "divider" },
    ];

    return (
        <SideNavigation
            data-testid={"side-nav"}
            header={header}
            items={items}
            activeHref={location.pathname}
            onFollow={onFollowHandler}
        />
    );
};

export default SideNavigationMenu;
