import {
    ColumnLayout,
    Container,
    DateInput,
    FormField,
    Header,
    Input,
    SpaceBetween,
} from "@amzn/awsui-components-react";
import FormFieldWithSelect from "common/FormFieldWithSelect/FormFieldWithSelect";
import HtmlEditor from "common/HtmlEditor/HtmlEditor";
import { getString } from "common/ui-string-labels/ui-strings-utils";
import { CatalogItemValues } from "interfaces/catalogSchema";
import { useState } from "react";

interface InventoryInformationFormProps {
    catalogItemValues: CatalogItemValues;
    handleInputChange: (field: keyof CatalogItemValues, value: string) => void;
    errors: {
        restock_thresholdError: string;
        retail_priceError: string;
        eta_daysError: string;
    };
}

const licenseTypeOptions = [
    { label: "Single seat", value: "single_seat" },
    { label: "Multiples seats", value: "multi_seat" },
];

const InventoryInformationForm = ({ catalogItemValues, handleInputChange, errors }: InventoryInformationFormProps) => {
    const [quoteDate, setQuoteDate] = useState(catalogItemValues.quote_date);

    /* istanbul ignore next */
    return (
        <Container
            data-testid="inventory-information-form"
            header={<Header variant="h2">{getString("createCatalog.formInventoryInformation.formHeader")}</Header>}
        >
            <SpaceBetween size="m">
                <ColumnLayout columns={3}>
                    <FormField
                        data-testid="formfield-test"
                        label={getString("createCatalog.formInventoryInformation.inputProductDownloadUrl")}
                    >
                        <Input
                            onChange={({ detail }) => handleInputChange("product_download_url", detail.value)}
                            value={catalogItemValues.product_download_url}
                        />
                    </FormField>
                    <FormField
                        errorText={errors.restock_thresholdError}
                        data-testid="formfield-test"
                        label={getString("createCatalog.formInventoryInformation.inputRestockThreshold")}
                    >
                        <Input
                            data-cy="product-restock-threshold-input"
                            inputMode="numeric"
                            ariaRequired
                            onChange={({ detail }) => handleInputChange("restock_threshold", detail.value)}
                            value={catalogItemValues.restock_threshold}
                        />
                    </FormField>
                    <FormField
                        errorText={errors.retail_priceError}
                        data-testid="formfield-test"
                        label={getString("createCatalog.formInventoryInformation.inputRetailPrice")}
                    >
                        <Input
                            data-cy="product-retail-price-input"
                            inputMode="numeric"
                            ariaRequired
                            onChange={({ detail }) => handleInputChange("retail_price", detail.value)}
                            value={catalogItemValues.retail_price}
                        />
                    </FormField>
                </ColumnLayout>
                <ColumnLayout columns={2}>
                    <FormFieldWithSelect
                        data-testid="formfield-test"
                        selectedValue={catalogItemValues.license_type}
                        label={getString("createCatalog.formInventoryInformation.inputLicenseType")}
                        options={licenseTypeOptions}
                        onSelectionChange={(value) => handleInputChange("license_type", value)}
                    />
                    <FormField
                        errorText={errors.eta_daysError}
                        data-testid="formfield-test"
                        label={getString("createCatalog.formInventoryInformation.inputEtaDays")}
                    >
                        <Input
                            data-cy="product-eta-days-input"
                            inputMode="numeric"
                            ariaRequired
                            onChange={({ detail }) => handleInputChange("eta_days", detail.value)}
                            value={catalogItemValues.eta_days}
                        />
                    </FormField>
                </ColumnLayout>
                <FormField
                    data-testid="formfield-test"
                    stretch
                    label={getString("createCatalog.formInventoryInformation.inputLicenseInstructions")}
                >
                    <HtmlEditor
                        value={catalogItemValues.license_activation_instructions}
                        onChange={(value) => handleInputChange("license_activation_instructions", value)}
                    />
                </FormField>
                <ColumnLayout columns={2}>
                    <FormField
                        data-testid="formfield-test"
                        label={
                            <span>
                                {getString("createCatalog.formInventoryInformation.inputRecurrence")}
                                <i>{getString("createCatalog.formLabels.italicOptional")}</i>
                            </span>
                        }
                    >
                        <Input
                            onChange={({ detail }) => handleInputChange("recurrence", detail.value)}
                            value={catalogItemValues.recurrence}
                        />
                    </FormField>
                    <FormField
                        data-testid="formfield-test"
                        label={getString("createCatalog.formInventoryInformation.inputOrderWorkflow")}
                    >
                        <Input
                            onChange={({ detail }) => handleInputChange("order_workflow", detail.value)}
                            value={catalogItemValues.order_workflow}
                        />
                    </FormField>
                </ColumnLayout>
                <ColumnLayout columns={2}>
                    <FormField
                        data-testid="formfield-test"
                        label={
                            <span>
                                {getString("createCatalog.formInventoryInformation.inputQuoteNumber")}
                                <i>{getString("createCatalog.formLabels.italicOptional")}</i>
                            </span>
                        }
                    >
                        <Input
                            onChange={({ detail }) => handleInputChange("quote_number", detail.value)}
                            value={catalogItemValues.quote_number}
                        />
                    </FormField>
                    <FormField
                        data-testid="formfield-test"
                        label={
                            <span>
                                {getString("createCatalog.formInventoryInformation.inputQuoteDate")}
                                <i>{getString("createCatalog.formLabels.italicOptional")}</i>
                            </span>
                        }
                    >
                        <DateInput
                            onChange={({ detail }) => {
                                // This regexp replaces the '/' from the date to a '-' so the item is accepted
                                const formattedDate = detail.value.replace(/\//g, "-");

                                handleInputChange("quote_date", formattedDate);
                                setQuoteDate(detail.value);
                            }}
                            value={quoteDate}
                            placeholder="YYYY/MM/DD"
                        />
                    </FormField>
                </ColumnLayout>
            </SpaceBetween>
        </Container>
    );
};

export default InventoryInformationForm;
