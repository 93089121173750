import {
    Alert,
    Box,
    Button,
    ColumnLayout,
    Container,
    Form,
    FormField,
    Header,
    Icon,
    Input,
    Link,
    Modal,
    Select,
    SpaceBetween,
} from "@amzn/awsui-components-react";
import { OrderStatus } from "@amzn/ito-client";
import CopyText from "common/CopyText/CopyText";
import { useFlashBarItemsActions } from "common/UseFlashBarItems/useFlashBarItems";
import { Fragment, FunctionComponent } from "react";

import { useCreateOrderModal, useCreateOrderModalResult } from "./useCreateOrderModal";

export interface CreateOrderModalProps {
    onDismiss: (shouldRefreshTable?: boolean) => void;
    flashBarItemsActions: useFlashBarItemsActions;
}

export const CreateOrderModal: FunctionComponent<CreateOrderModalProps> = (props: CreateOrderModalProps) => {
    const modalHook = useCreateOrderModal({
        onCloseModal: () => {
            props.onDismiss(true);
        },
    });
    const { actions, state: attributes } = modalHook;

    const buildModalDetails = (): JSX.Element[] => {
        // If attributes
        if (attributes.orderSubmitted) {
            return ViewOrderModal(modalHook, false);
        } else if (attributes.orderPreview) {
            return ViewOrderModal(modalHook, true);
        } else {
            return CreateOrdersForm(modalHook);
        }
    };

    const [bodyElement, footerElement] = buildModalDetails();

    return (
        <Modal
            data-testid="create-order-modal"
            onDismiss={actions.closeModal}
            visible={true}
            header={<Header variant="h2">Create Order</Header>}
            footer={<Box float="right">{footerElement}</Box>}
        >
            {bodyElement}
        </Modal>
    );
};

export const CreateOrdersForm = (hook: useCreateOrderModalResult) => {
    const { actions, state: attributes } = hook;
    const {
        useForm: { actions: formActions, state: formState },
    } = hook;

    const errorWrapper = (error: string) => {
        if (error !== "Unexpected error") {
            return "Please review the username.";
        } else return error;
    };

    /* istanbul ignore next */
    const buildFooter = () => {
        const disableFooter = attributes.isLoading === true;

        return (
            <SpaceBetween direction="horizontal" size="xs">
                <Button data-testid="close-button" variant="link" onClick={actions.closeModal} disabled={disableFooter}>
                    Close
                </Button>
                <Button
                    data-testid="preview-button"
                    variant="primary"
                    onClick={() => {
                        formActions.onClickPreview();
                    }}
                    data-cy="admin-preview-orders-button"
                    loading={disableFooter}
                >
                    Preview
                </Button>
            </SpaceBetween>
        );
    };

    const form = (
        <Fragment>
            <Form
                data-testid="create-order-form"
                errorText={attributes.error ? `Error submitting dry run order: ${errorWrapper(attributes.error)}` : ""}
            >
                <SpaceBetween size="l">
                    <FormField
                        description="Search by item name."
                        label="Inventory Item"
                        errorText={formState.itemSelectedErrorText}
                    >
                        <Select
                            filteringType="manual"
                            onChange={({ detail }) => {
                                formActions.setItemSelected(detail.selectedOption);
                            }}
                            onLoadItems={({ detail: { filteringText } }) => {
                                formActions.handleLoadItems(filteringText);
                            }}
                            options={formState.options}
                            selectedOption={formState.itemSelected}
                            empty={"No items found with given text"}
                            loadingText="Searching inventory items"
                            statusType={formState.statusType}
                        />
                    </FormField>
                    <FormField
                        description="Enter the login of the requester. eg: linl, oscorde."
                        label="Requester login"
                        errorText={formState.requesterFieldErrorText}
                    >
                        <Input
                            value={formState.requesterField}
                            onChange={(event) => formActions.setRequesterField(event.detail.value)}
                        />
                    </FormField>
                </SpaceBetween>
            </Form>
        </Fragment>
    );

    return [form, buildFooter()];
};

export const ViewOrderModal = (hook: useCreateOrderModalResult, preview?: boolean): JSX.Element[] => {
    const { actions, state: attributes } = hook;
    const order = preview ? hook.state.orderPreview! : hook.state.orderSubmitted!;

    const requesterInfo = order.requester?.basicInfo!;
    const itemName = (order.items![0].itemInformation as any).name;
    const requesterUsername = requesterInfo.emailAddress!.split("@")[0];
    const placedBy = order.statusHistory?.find((status) => (status.status = OrderStatus.NEW))?.by;

    /* istanbul ignore next */
    const buildFooter = () => {
        const disableFooter = attributes.isLoading === true;
        const secundaryButtonText = preview ? "Go back" : "Place another order";

        return (
            <SpaceBetween direction="horizontal" size="xs">
                <Button
                    data-testid="close-button"
                    variant="link"
                    onClick={actions.goBackToForm}
                    disabled={disableFooter}
                >
                    {secundaryButtonText}
                </Button>
                {preview && (
                    <Button
                        data-testid="preview-button"
                        variant="primary"
                        onClick={() => {
                            actions.submitOrder();
                        }}
                        data-cy="admin-submit-orders-button"
                        loading={disableFooter}
                    >
                        Submit
                    </Button>
                )}
            </SpaceBetween>
        );
    };

    const buildBody = (
        <Fragment>
            <SpaceBetween size="l">
                <Container header={<Header headingTagOverride="h4">{"Order preview"}</Header>}>
                    <ColumnLayout columns={2} variant="text-grid">
                        <SpaceBetween size="l">
                            <ValueWithLabel label="Requester">{`${order.requester?.basicInfo?.firstName} ${order.requester?.basicInfo?.lastName}`}</ValueWithLabel>
                            <ValueWithLabel label="Username">
                                <Link
                                    href={`https://phonetool.amazon.com/users/${requesterUsername}`}
                                    target={"_blank"}
                                >
                                    @{requesterUsername} <Icon name="external" />
                                </Link>
                            </ValueWithLabel>
                            <ValueWithLabel label="Email">
                                <CopyText
                                    textContent={requesterInfo.emailAddress}
                                    copyContent={requesterInfo.emailAddress || ""}
                                    isHtml={false}
                                />
                            </ValueWithLabel>
                        </SpaceBetween>
                        <SpaceBetween size="l">
                            <ValueWithLabel label="Item requested">{`${itemName}`}</ValueWithLabel>
                            <ValueWithLabel label="Created by">{`${placedBy}`}</ValueWithLabel>
                        </SpaceBetween>
                    </ColumnLayout>
                </Container>
                {!preview && (
                    <Alert type="success" header={<Header headingTagOverride="h3">{""}</Header>}>
                        {`Order created successfully. Order Number: ${order.orderReference}.\n`}
                        You can create another order or close the modal now.
                    </Alert>
                )}
            </SpaceBetween>
        </Fragment>
    );
    return [buildBody, buildFooter()];
};

interface ValueWithLabelProps {
    label: string;
    children: React.ReactElement | string;
}

export const ValueWithLabel = (props: ValueWithLabelProps) => (
    <div>
        <Box variant="awsui-key-label">{props.label}</Box>
        <div>{props.children}</div>
    </div>
);
