import { useCollection } from "@amzn/awsui-collection-hooks";
import {
    ButtonDropdownProps,
    CollectionPreferencesProps,
    PaginationProps,
    PropertyFilterProps,
    SelectProps,
    TableProps,
} from "@amzn/awsui-components-react";
import {
    CancelableEventHandler,
    ClickDetail,
    NonCancelableEventHandler,
} from "@amzn/awsui-components-react/polaris/internal/events";
import { GetInventoryCommandOutput, Inventory } from "@amzn/tinvent-typescript-client";
import { Preferences } from "common/ui-helpers";
import { getString } from "common/ui-string-labels/ui-strings-utils";
import { useFlashBarItemsActions } from "common/UseFlashBarItems/useFlashBarItems";
import {
    buildColumnDefinitions,
    buildFilteringProperties,
    buildVisibleContentOptions,
    convertTokensToPropertyFilter,
    defaultPagingOptions,
    SortingState,
} from "common/utils";
import { ColumnSetting } from "common/utils/config-models";
import { Error, useGetInventory, usePatchInventoryNote } from "hooks";
import { usePreferences } from "hooks/usePreferences";
import { CurrentPage } from "interfaces";
import { useEffect, useState } from "react";
import { CSRFService } from "services/csrf-service";

import inventoryTableConfig from "./inventory.table-config.json";

interface useManageLicenseTableActions {
    tableActions: {
        onItemClick: CancelableEventHandler<ButtonDropdownProps.ItemClickDetails> | undefined;
    };
    filtering: {
        onSelectedRestockFlagChange: NonCancelableEventHandler<SelectProps.ChangeDetail>;
        onPropertyFilterChange: NonCancelableEventHandler<PropertyFilterProps.Query>;
    };
    pagination: {
        onPageClick: NonCancelableEventHandler<PaginationProps.ChangeDetail>;
    };
    preferences: {
        onPreferencesConfirmHandler: NonCancelableEventHandler<CollectionPreferencesProps.Preferences<any>>;
    };
    sorting: {
        onSortingChange: NonCancelableEventHandler<TableProps.SortingState<Inventory>>;
    };
    selection: {
        setSelectedItems: React.Dispatch<React.SetStateAction<Inventory[]>>;
        onSelectionChangeHandler: NonCancelableEventHandler<TableProps.SelectionChangeDetail<any>>;
    };
    modal: {
        dismissUpdateNoteModal: (shouldRefreshTable?: boolean | undefined) => void;
        dismissDeleteNoteModal: (shouldRefreshTable?: boolean | undefined) => void;
        onDeleteNote: CancelableEventHandler<ClickDetail> | undefined;
    };
}

interface useManageLicenseTableAttributes {
    inventoryData: GetInventoryCommandOutput | undefined;
    dropdownValues: {
        inventoryFlagOptions: Array<{ label: string; value: string }>;
        actionOptions: readonly ButtonDropdownProps.ItemOrGroup[];
    };
    tablePreferences: {
        visibleContentOptions: CollectionPreferencesProps.VisibleContentOption[];
    };
    filtering: {
        properties: PropertyFilterProps.FilteringProperty[];
    };
    error: Error;
    currentColumnDefinitions: TableProps.ColumnDefinition<any>[];
    currentPreferences: Preferences;
    collectionProps: any;
    totalResults: number;
    state: {
        selectedItems: Inventory[];
        selectedRestockFlag: any;
        currentPageIndex: CurrentPage;
        currentFilteringQuery: PropertyFilterProps.Query;
        isLoading: boolean;
        pagesCount: number;
        updateInventoryNoteModalVisible: boolean;
        deleteNoteModalVisible: boolean;
        isProcessing: boolean;
    };
}

export interface useManageLicenseTableResult {
    attributes: useManageLicenseTableAttributes;
    actions: useManageLicenseTableActions;
}

export interface useManageLicenseTableProps {
    flashBarItemsActions: useFlashBarItemsActions;
}

export function useManageLicenseTable(props: useManageLicenseTableProps): useManageLicenseTableResult {
    // State
    const [selectedItems, setSelectedItems] = useState<Inventory[]>([]);
    const [currentSorting, setCurrentSorting] = useState<SortingState | undefined>(undefined);
    const [currentPageIndex, setCurrentPageIndex] = useState<CurrentPage>({ index: 1, isPageReset: true });
    const [currentFilteringQuery, setFilteringQuery] = useState<PropertyFilterProps.Query>({
        tokens: [],
        operation: "and",
    });
    const [updateInventoryNoteModalVisible, setUpdateInventoryNoteModalVisible] = useState(false);
    const [deleteNoteModalVisible, setDeleteNoteModalVisible] = useState(false);

    const [data, isProcessing, inventoryNoteError, doPatchUpdateInventoryNotes] = usePatchInventoryNote(undefined);

    // Constants
    const inventoryFlagOptions = [
        { label: "Inventory Status", value: "" },
        { label: "All", value: "All" },
        { label: "Low", value: "Low" },
    ];
    const actionOptions: readonly ButtonDropdownProps.ItemOrGroup[] = [
        {
            text: getString("manageLicense.table.actions.updateNote"),
            id: "updateNote",
            disabled: selectedItems.length === 0,
        },
        {
            text: getString("manageLicense.table.actions.deleteNote"),
            id: "deleteNote",
            disabled: selectedItems.length === 0,
        },
    ];
    const columns = inventoryTableConfig.columns;
    const preferencesKey = "ManageLicensePreferences";

    const filteringProperties = buildFilteringProperties(columns);
    const visibleContentOptions = buildVisibleContentOptions(columns);

    // Filtering
    const [selectedRestockFlag, setSelectedRestockFlag] = useState(inventoryFlagOptions[0]);

    /* istanbul ignore next */
    const onSelectedRestockFlagChange: NonCancelableEventHandler<SelectProps.ChangeDetail> = (e) => {
        setSelectedRestockFlag({
            label: e.detail.selectedOption.label!,
            value: e.detail.selectedOption.value!,
        });
    };

    // Preferences
    const { currentPreferences, onPreferencesConfirmHandler } = usePreferences({
        preferencesKey: preferencesKey,
        refreshData: () => refreshTableData(true),
        columnsSettings: columns,
        defaultPaging: defaultPagingOptions[0].value,
    });

    // Get data from TInvent
    const [inventoryData, isLoading, error, doGetInventory] = useGetInventory(
        {
            pageSize: currentPreferences.pageSize!,
            pageIndex: 1,
        },
        undefined,
    );

    /* istanbul ignore next */
    const refreshTableData = (resetPage: boolean) => {
        let pageIndexForQuery = currentPageIndex.index;
        if (resetPage) {
            pageIndexForQuery = 1;
            setCurrentPageIndex({ index: 1, isPageReset: true });
            setSelectedItems([]);
        }

        const filters: { [key: string]: string[] } = convertTokensToPropertyFilter(currentFilteringQuery.tokens);
        if (currentSorting && currentSorting.sortOrder && currentSorting.sortBy) {
            filters[currentSorting.sortOrder] = [currentSorting.sortBy];
        }

        if (selectedRestockFlag.value) {
            filters.hasLowInventory = [(selectedRestockFlag.value == "Low").toString()];
        }

        doGetInventory({
            filters,
            pageSize: currentPreferences.pageSize!,
            pageIndex: pageIndexForQuery,
        });
    };

    /* istanbul ignore next */
    const onPropertyFilterChange: NonCancelableEventHandler<PropertyFilterProps.Query> = (e) => {
        setFilteringQuery(e.detail);
    };

    // Pagination
    /* istanbul ignore next */
    const onPageClick: NonCancelableEventHandler<PaginationProps.ChangeDetail> = (e) => {
        setCurrentPageIndex({ index: e.detail.currentPageIndex, isPageReset: false });
    };

    const { collectionProps } = useCollection(inventoryData?.items ?? [], {
        sorting: {},
    });

    // Sorting
    /* istanbul ignore next */
    const onSortingChange: NonCancelableEventHandler<TableProps.SortingState<Inventory>> = async (e) => {
        const sortBy = e.detail.sortingColumn.sortingField;
        const sortOrder = e.detail.isDescending ? "sort.desc" : "sort.asc";
        setCurrentSorting({ sortBy, sortOrder });
        collectionProps.onSortingChange!(e);
    };

    // Selection
    /* istanbul ignore next */
    const onSelectionChangeHandler: NonCancelableEventHandler<TableProps.SelectionChangeDetail<any>> = (e) => {
        setSelectedItems(e.detail.selectedItems);
    };

    // Table Actions
    /* istanbul ignore next */
    const onItemClick: CancelableEventHandler<ButtonDropdownProps.ItemClickDetails> | undefined = (e) => {
        switch (e.detail.id) {
            case "updateNote":
                setUpdateInventoryNoteModalVisible(true);
                break;
            case "deleteNote":
                setDeleteNoteModalVisible(true);
                break;
        }
    };

    // Modals
    /* istanbul ignore next */
    const dismissUpdateNoteModal = (shouldRefreshTable: boolean = false) => {
        if (shouldRefreshTable) {
            refreshTableData(true);
        }
        setUpdateInventoryNoteModalVisible(false);
    };

    /* istanbul ignore next */
    const dismissDeleteNoteModal = (shouldRefreshTable: boolean = false) => {
        if (shouldRefreshTable) {
            refreshTableData(true);
        }
        setDeleteNoteModalVisible(false);
    };

    /* istanbul ignore next */
    const onDeleteNote: CancelableEventHandler<ClickDetail> | undefined = (e) => {
        // Fetch CSRF token
        const csrfService = new CSRFService();
        csrfService.getToken().then(async (token: string) => {
            await doPatchUpdateInventoryNotes(
                selectedItems.map((i) => i.productTaxonomyId!),
                token,
                undefined,
            );
        });
    };

    // Ready to display
    let pagesCount = 0;
    let totalResults = 0;
    if (inventoryData && inventoryData.totalResults && inventoryData.totalResults > 0) {
        totalResults = inventoryData.totalResults;
        pagesCount = Math.ceil(inventoryData.totalResults / currentPreferences.pageSize!);
    }

    // Prepare columns
    const currentColumnDefinitions = buildColumnDefinitions(columns, currentPreferences.visibleContent);

    // Filtering and Sorting
    /* istanbul ignore next */
    useEffect(() => {
        refreshTableData(true);
    }, [currentFilteringQuery, currentSorting?.sortBy, currentSorting?.sortOrder, selectedRestockFlag]);

    // Pagination
    useEffect(() => {
        if (!currentPageIndex.isPageReset) {
            refreshTableData(false);
        }
    }, [currentPageIndex.index, currentPageIndex.isPageReset, selectedRestockFlag]);

    /* istanbul ignore next */
    useEffect(() => {
        if (data) {
            dismissDeleteNoteModal(true);
            props.flashBarItemsActions.addSuccessFlashBarItem(
                getString("manageLicense.deleteInventoryNoteModal.success"),
            );
        }
    }, [data]);

    /* istanbul ignore next */
    useEffect(() => {
        if (error && error.hasError) {
            dismissDeleteNoteModal(false);
            props.flashBarItemsActions.addErrorFlashBarItem(
                `${getString("manageLicense.deleteInventoryNoteModal.error")}. ${error.message}`,
            );
        }
    }, [error]);

    return {
        attributes: {
            currentColumnDefinitions,
            collectionProps,
            currentPreferences,
            error,
            inventoryData,
            totalResults,
            dropdownValues: {
                inventoryFlagOptions,
                actionOptions,
            },
            filtering: {
                properties: filteringProperties,
            },
            state: {
                selectedRestockFlag,
                currentFilteringQuery,
                currentPageIndex,
                isLoading,
                pagesCount,
                selectedItems,
                updateInventoryNoteModalVisible,
                deleteNoteModalVisible,
                isProcessing,
            },
            tablePreferences: {
                visibleContentOptions,
            },
        },
        actions: {
            filtering: {
                onPropertyFilterChange,
                onSelectedRestockFlagChange,
            },
            pagination: {
                onPageClick,
            },
            preferences: {
                onPreferencesConfirmHandler,
            },
            sorting: {
                onSortingChange,
            },
            selection: {
                setSelectedItems,
                onSelectionChangeHandler,
            },
            tableActions: {
                onItemClick,
            },
            modal: {
                dismissUpdateNoteModal,
                dismissDeleteNoteModal,
                onDeleteNote,
            },
        },
    };
}
