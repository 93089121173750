import { ColumnLayout, Container, FormField, Header, Input, TokenGroup } from "@amzn/awsui-components-react";
import { BaseKeyDetail } from "@amzn/awsui-components-react/polaris/internal/events";
import { getString } from "common/ui-string-labels/ui-strings-utils";
import { CatalogItemValues } from "interfaces/catalogSchema";
import { useState } from "react";

interface ProductCategoryFormProps {
    catalogItemValues: CatalogItemValues;
    handleInputChange: (field: keyof CatalogItemValues, value: string[]) => void;
}

const ProductCategoriesForm = ({ catalogItemValues, handleInputChange }: ProductCategoryFormProps) => {
    // State for the current category being typed
    const [currentCategory, setCurrentCategory] = useState("");

    // Event handler for key up events in the input field
    /* istanbul ignore next */
    const handleKeyDown = (event: CustomEvent<BaseKeyDetail>) => {
        // Check if Enter key is pressed and currentCategory is not empty
        if (event.detail.key === "Enter" && currentCategory) {
            // Add the current category to the formValues categories array
            handleInputChange("categories", [...catalogItemValues.categories, currentCategory]);
            // Reset currentCategory to clear the input field
            setCurrentCategory("");
            // Prevent the default action of the Enter key
            event.preventDefault();
        }
    };

    // Event handler for dismissing (removing) a category token
    /* istanbul ignore next */
    const handleDismiss = (itemIndex: number) => {
        // Remove the dismissed category from the formValues categories array
        const updatedCategories = catalogItemValues.categories.filter((_, index) => index !== itemIndex);
        handleInputChange("categories", updatedCategories);
    };

    /* istanbul ignore next */
    return (
        <Container
            data-testid="product-categories-form"
            header={
                <Header description={getString("createCatalog.formProductCategories.formDescription")} variant="h2">
                    {getString("createCatalog.formProductCategories.formHeader")}
                </Header>
            }
        >
            <ColumnLayout columns={2}>
                <FormField
                    data-testid="formfield-test"
                    label={getString("createCatalog.formProductCategories.inputCategories")}
                >
                    <Input
                        onKeyDown={handleKeyDown}
                        onChange={({ detail }) => setCurrentCategory(detail.value)}
                        value={currentCategory}
                    />
                </FormField>
                <FormField
                    data-testid="formfield-test"
                    label={getString("createCatalog.formProductCategories.finalCategories")}
                >
                    <TokenGroup
                        disableOuterPadding
                        onDismiss={({ detail: { itemIndex } }) => handleDismiss(itemIndex)}
                        items={catalogItemValues.categories.map((category, index) => ({
                            label: category,
                            dismissLabel: `Remove ${category}`,
                            index: index, // to uniquely identify each category
                        }))}
                    />
                </FormField>
            </ColumnLayout>
        </Container>
    );
};

export default ProductCategoriesForm;
