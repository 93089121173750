import { decode } from "base64-arraybuffer";
import { StatusCodes } from "http-status-codes";
import fileDownload from "js-file-download";
import moment from "moment";

import { API_BASE_URL } from "../configuration/config";
import { BaseService } from "./base-service";
import { CSRFService } from "./csrf-service";
import { UserPermissions } from "./user-permissions";

export const LICENSE_UPLOAD_TEMPLATE_FILE_NAME = "license-upload-template.xlsx";
export const ORDERS_UPLOAD_TEMPLATE_FILE_NAME = "orders-upload-template.xlsx";
export const ORDERS_FILE_NAME = "orders";

/**
 * A service for downloading the license template
 */
export class FilesService extends BaseService {
    /**
     * Singleton instance for download service
     */
    public static instance = new FilesService(API_BASE_URL);

    public async downloadLicenseUploadTemplate() {
        const response = await this.fetch("/get-license-upload-template");
        if (response.status !== StatusCodes.OK) {
            throw new Error("An error occurred when downloading the license upload template.");
        }
        const fileContent = await response.text();

        fileDownload(decode(fileContent), LICENSE_UPLOAD_TEMPLATE_FILE_NAME);
    }

    public async downloadOrdersUploadTemplate() {
        const response = await this.fetch("/get-orders-upload-template");
        if (response.status !== StatusCodes.OK) {
            throw new Error("An error occurred when downloading the orders upload template.");
        }
        const fileContent = await response.text();

        fileDownload(decode(fileContent), ORDERS_UPLOAD_TEMPLATE_FILE_NAME);
    }

    public downloadTinventFile = async (fileIdGuid: string, fileName: string, type: string) => {
        const response = await this.fetch(`/tinvent/file/presignedurl/${fileIdGuid}?type=${type}`);
        if (response.status !== StatusCodes.OK) {
            throw new Error(`An error occurred when downloading the ${type} file.`);
        }
        const fileContent = await response.text();
        fileDownload(decode(fileContent), fileName);
    };

    public async downloadOrders() {
        const response: Response = await this.fetch(
            `/ito/orders/download?status=APPROVED&shouldUpdateToStatus=PROCESSING`,
        );
        if (response.status !== StatusCodes.OK) {
            throw new Error(`An error occurred when downloading orders.`);
        }

        const fileName = this.getFileNameFromHeader(response.headers) ?? this.createFileName();

        const fileContent = await response.json();
        fileDownload(decode(fileContent), fileName);
    }

    public downloadOrdersHistory = async (fileIdGuid: string, fileName: string, type: string) => {
        const response = await this.fetch(`/ito/file/presignedurl/${fileIdGuid}?type=${type}`);
        if (response.status !== StatusCodes.OK) {
            throw new Error(`An error occurred when downloading the orders file.`);
        }
        const fileContent = await response.text();
        fileDownload(decode(fileContent), fileName);
    };

    /* istanbul ignore next */
    public async uploadBulkFile(file: File): Promise<boolean> {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = async (e) => {
                const content = e.target?.result as ArrayBuffer;

                const csrfService = new CSRFService();
                csrfService.getToken().then((token: string) => {
                    try {
                        // fetch
                        this.fetch("/tinvent/bulk", {
                            method: "POST",
                            body: content,
                            headers: {
                                "Content-Type": file.type,
                                "X-Tinvent-File-Name": file.name,
                                "X-Tinvent-Uploaded-By": UserPermissions.instance.username,
                                "x-csrf-token": token,
                            },
                        }).then((response) => {
                            resolve(response.ok);
                        });
                    } catch (error) {
                        resolve(false);
                    }
                });
            };

            reader.onerror = () => {
                resolve(false);
            };

            reader.readAsArrayBuffer(file);
        });
    }

    /* istanbul ignore next */
    public async uploadBulkOrders(file: File): Promise<boolean> {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = async (e) => {
                const content = e.target?.result as ArrayBuffer;

                const csrfService = new CSRFService();
                csrfService.getToken().then((token: string) => {
                    try {
                        // fetch
                        this.fetch("/ito/files/bulkupload", {
                            method: "POST",
                            body: content,
                            headers: {
                                "Content-Type": file.type,
                                "X-Ito-File-Name": file.name,
                                "x-csrf-token": token,
                            },
                        }).then((response) => {
                            resolve(response.ok);
                        });
                    } catch (error) {
                        resolve(false);
                    }
                });
            };

            reader.onerror = () => {
                resolve(false);
            };

            reader.readAsArrayBuffer(file);
        });
    }

    public getFileNameFromHeader(headers: Headers): string | undefined {
        let contentHeader: string | null = null;
        if (headers) {
            contentHeader = headers.get("Content-Disposition") || headers.get("content-disposition");
        }

        let fileName: string | undefined = undefined;
        if (contentHeader) {
            // Gets the file name from the header:
            // From 'Content-Disposition': 'attachment; filename="orders_20231002154008223.xlsx"'
            // Returns orders_20231002154008223.xlsx
            const regexFilename = /filename="(?<fileInfo>.*)"/;
            const matchResult = regexFilename.exec(contentHeader);

            if (matchResult?.groups && matchResult?.groups?.fileInfo) {
                fileName = matchResult?.groups?.fileInfo;
            }
        }

        return fileName;
    }

    public createFileName(): string {
        const dateFormat = moment(new Date()).utc().format("YYYYMMDDHHmmssSSS");
        const fileName = `${ORDERS_FILE_NAME}_${dateFormat}.xlsx`;
        return fileName;
    }
}
