import { BrowserRouter, Route, Routes } from "react-router-dom";
import DownloadedOrdersPage from "views/DownloadedOrders";

import CustomHeader from "../common/CustomHeader/CustomHeader";
import Paths from "../common/paths/paths";
import CreateCatalogPage from "../views/CreateCatalogPage";
import EditCatalogPage from "../views/EditCatalogPage";
import LicenseDetailsPage from "../views/LicenseDetailsPage";
import ManageCatalogPage from "../views/ManageCatalogPage";
import ManageLicensePage from "../views/ManageLicensePage";
import ManageOrdersPage from "../views/ManageOrdersPage";
import OrdersUploadedPage from "../views/OrdersUploadedPage";
import UploadLicensePage from "../views/UploadLicensePage";
import UploadOrdersPage from "../views/UploadOrdersPage";

const AdminRoutes = () => (
    <BrowserRouter>
        <CustomHeader />
        <Routes>
            <Route path={Paths.uploadLicense.url} element={<UploadLicensePage />} />
            <Route path={Paths.manageLicense.url} element={<ManageLicensePage />} />
            <Route path={Paths.manageOrders.url} element={<ManageOrdersPage />} />
            <Route path={Paths.manageCatalog.url} element={<ManageCatalogPage />} />
            <Route path={Paths.createCatalog.url} element={<CreateCatalogPage />} />
            <Route path={Paths.editCatalog.url} element={<EditCatalogPage />} />
            <Route path={Paths.licenseDetails.url} element={<LicenseDetailsPage />} />
            <Route path={Paths.uploadOrders.url} element={<UploadOrdersPage />} />
            <Route path={Paths.ordersUploaded.url} element={<OrdersUploadedPage />} />
            <Route path={Paths.ordersDownloaded.url} element={<DownloadedOrdersPage />} />
            <Route path="/*" element={<ManageOrdersPage />} />
        </Routes>
    </BrowserRouter>
);

export default AdminRoutes;
