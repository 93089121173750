import { useFlashBarItems } from "common/UseFlashBarItems/useFlashBarItems";
import useInput from "hooks/useInput";
import { CatalogItemValues } from "interfaces/catalogSchema";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CatalogicalServiceV2 } from "services/catalogical-service-v2";

const useCreateCatalogForm = () => {
    const navigate = useNavigate();
    const [submitStatus, setSubmitStatus] = useState<{ loading: boolean; error: string | null }>({
        loading: false,
        error: null,
    });
    const [isLeaveModalVisible, setIsLeaveModalVisible] = useState(false);
    const { flashBarItems, actions } = useFlashBarItems();

    // Function to handle the cancel button click, shows the leave modal
    /* istanbul ignore next */
    const handleCancelClick = () => {
        setIsLeaveModalVisible(true);
    };

    /* istanbul ignore next */
    const handleDismissModal = () => {
        setIsLeaveModalVisible(false);
    };

    /* istanbul ignore next */
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };

    const { catalogItemValues, handleInputChange } = useInput<CatalogItemValues>({
        //Taxonomy Form Values
        subtype: "",
        manufacturer: "",
        short_name: "",
        sku: "",

        //Product Information Form Values
        name: "",
        amount: "",
        os: [],
        order_redirect_url: "",
        short_description: "",
        description: "",

        //Inventory Information Form Values
        product_download_url: "",
        restock_threshold: "",
        retail_price: "",
        license_activation_instructions: "",
        quote_number: "",
        quote_date: "",
        currency: "",
        status: "",

        //Product Categories Form Values
        categories: [],

        //Available Regions Form Values
        region_restrictions: [],
        eta_days: "",
        license_type: "",
        recurrence: "",
        order_workflow: "",
    });

    // State to track errors in required fields
    const [requiredFieldsErrors, setRequiredFieldsErrors] = useState({
        nameError: "",
        subtypeError: "",
        manufacturerError: "",
        shortNameError: "",
        skuError: "",
        amountError: "",
        retail_priceError: "",
        restock_thresholdError: "",
        eta_daysError: "",
        currencyError: "",
        statusError: "",
    });

    // New validateRequiredFields method to encapsulate validation logic
    /* istanbul ignore next */
    const validateRequiredFields = () => {
        const errors = {
            subtypeError: catalogItemValues.subtype ? "" : "Subtype is a required field",
            manufacturerError: catalogItemValues.manufacturer ? "" : "Vendor is a required field",
            shortNameError: catalogItemValues.short_name ? "" : "Short Name is a required field",
            skuError: catalogItemValues.sku ? "" : "SKU is a required field",
            nameError: catalogItemValues.name ? "" : "Product Name is a required field",
            amountError: catalogItemValues.amount ? "" : "Cost is a required field",
            retail_priceError: catalogItemValues.retail_price ? "" : "Retail Price is a required field",
            restock_thresholdError: catalogItemValues.restock_threshold ? "" : "Restock Threshold is a required field",
            eta_daysError: catalogItemValues.eta_days ? "" : "ETA Days is a required field",
            currencyError: catalogItemValues.currency ? "" : "Currency is a required field",
            statusError: catalogItemValues.status ? "" : "Status is a required field",
        };
        return errors;
    };

    // Modified validateForm method that uses validateRequiredFields
    const validateForm = () => {
        const newErrors = validateRequiredFields();
        // Updating the state with new error messages
        setRequiredFieldsErrors(newErrors);
        return Object.values(newErrors).every((error) => error === "");
    };

    // Function to handle the submission of the catalog creation form
    /* istanbul ignore next */
    const handleCreateCatalogButton = async () => {
        // Validate form before submission
        if (!validateForm()) {
            scrollToTop();
            return;
        }

        setSubmitStatus({ loading: true, error: null });
        try {
            // Attempting to create catalog item
            const response = await CatalogicalServiceV2.instance.createCatalogItem(catalogItemValues);
            // Redirect to catalog page with a new flashMessage state
            navigate("/catalog", {
                replace: true,
                state: { flashMessage: `${catalogItemValues.name} successfully created!` },
            });
        } catch (error) {
            // On error, show error message and update submission status
            const errorMessage = (error as Error).message;
            actions.addErrorFlashBarItem(errorMessage);
            setSubmitStatus({ loading: false, error: errorMessage });
            scrollToTop();
        }
    };

    return {
        catalogItemValues,
        handleInputChange,
        requiredFieldsErrors,
        handleCreateCatalogButton,
        submitStatus,
        flashBarItems,
        handleCancelClick,
        isLeaveModalVisible,
        handleDismissModal,
        validateForm,
    };
};
export default useCreateCatalogForm;
