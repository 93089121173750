import {
    Box,
    Button,
    CollectionPreferences,
    Flashbar,
    Grid,
    Header,
    PropertyFilter,
    Select,
    SpaceBetween,
    Table,
} from "@amzn/awsui-components-react";
import { Pagination } from "@amzn/awsui-components-react";
import EmptyTableBox from "common/EmptyTableBox/EmptyTableBox";
import { getPropertyFilterString, getString } from "common/ui-string-labels/ui-strings-utils";
import { useFlashBarItems } from "common/UseFlashBarItems/useFlashBarItems";
import { defaultPagingOptions } from "common/utils";
import { FC, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import InternalButton from "../../common/navigation/InternalButton";
import DeactivateItemModal from "./DeactivateItemModal/DeactivateItemModal";
import { useManageCatalogTable } from "./useManageCatalogTable";

const ManageCatalogTable: FC = () => {
    const { filtering, pagination, preferences, sorting, data } = useManageCatalogTable();

    const { actions, flashBarItems } = useFlashBarItems();
    const navigate = useNavigate();
    const location = useLocation();

    // useEffect to add the flashbar message when a new state comes
    /* istanbul ignore next */
    useEffect(() => {
        if (location.state?.flashMessage) {
            actions.addSuccessFlashBarItem(location.state?.flashMessage);
            navigate(location.pathname, { replace: true, state: {} });
            data.refetchCatalogItems();
        }
    }, [location.state?.flashMessage]);

    return (
        <Table
            {...data.collectionProps}
            sortingColumn={{
                sortingField: sorting.sortBy,
            }}
            sortingDescending={sorting.sortDirection === "desc"}
            onSortingChange={sorting.onSortingChange}
            data-testid="manage-catalog-table"
            data-cy="manage-catalog-table"
            onSelectionChange={data.onSelectionChangeHandler}
            selectedItems={data.selectedItems}
            items={data.catalogItems}
            loading={data.isLoading}
            resizableColumns={true}
            empty={<EmptyTableBox />}
            loadingText="Loading resources"
            selectionType="multi"
            stripedRows
            variant="full-page"
            wrapLines
            header={
                <SpaceBetween size="m">
                    <Flashbar items={flashBarItems} />
                    <Flashbar stackItems items={data.flashBarItems} />
                    <DeactivateItemModal
                        dataCy="confirm-deactivate-button"
                        visible={data.isLeaveModalVisible}
                        onDismiss={data.handleDismissModal}
                        deactivateItem={data.handleDeactivateItem}
                        loading={data.submitStatus.loading}
                        disabled={data.submitStatus.loading}
                    />
                    <Header
                        data-testid="manage-catalog-table-header"
                        variant="awsui-h1-sticky"
                        counter={data.counterText}
                        actions={
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button
                                    data-cy="deactivate-button"
                                    disabled={data.selectedItems.length === 0}
                                    onClick={data.handleDeactivateClick}
                                >
                                    {getString("manageCatalog.table.deactivateCatalogButton")}
                                </Button>
                                <InternalButton iconName="add-plus" variant="primary" href="/catalog/create">
                                    {getString("manageCatalog.table.createCatalogButton")}
                                </InternalButton>
                            </SpaceBetween>
                        }
                    >
                        {getString("manageCatalog.table.headerTitle")}
                    </Header>
                    <Box color="text-status-inactive">{getString("manageCatalog.table.headerDescription")}</Box>
                </SpaceBetween>
            }
            filter={
                <Grid gridDefinition={[{ colspan: 6 }, { colspan: 2 }, { colspan: 2 }, { colspan: 2 }]}>
                    <PropertyFilter
                        data-cy="property-filter"
                        filteringPlaceholder={getString("manageCatalog.table.textFilter")}
                        query={{
                            tokens: [...filtering.fuzzySearch, ...filtering.propertyFilterTokens],
                            operation: "and",
                        }}
                        onChange={filtering.onFilterChange}
                        filteringProperties={filtering.filteringProperties}
                        i18nStrings={getPropertyFilterString()}
                        hideOperations={true}
                        expandToViewport
                    />
                    <Select
                        data-testid="select-status"
                        selectedOption={filtering.selectedStatus}
                        options={filtering.statusOptions}
                        onChange={filtering.onStatusChange}
                    />
                    <Select
                        data-testid="select-active"
                        selectedOption={filtering.selectedActive}
                        options={filtering.isActiveOptions}
                        onChange={filtering.onActiveChange}
                    />
                </Grid>
            }
            pagination={
                <Pagination
                    currentPageIndex={pagination.currentPage}
                    pagesCount={pagination.pagesCount}
                    onChange={pagination.onChangePageHandler}
                    ariaLabels={{
                        nextPageLabel: getString("common.pagination.nextPage"),
                        previousPageLabel: getString("common.pagination.nextPage"),
                        pageLabel: (pageNumber: number) => getString("common.pagination.currentPage", { pageNumber }),
                    }}
                />
            }
            preferences={
                <CollectionPreferences
                    title={getString("common.preferences.title")}
                    confirmLabel={getString("common.preferences.confirmLabel")}
                    cancelLabel={getString("common.preferences.cancelLabel")}
                    pageSizePreference={{
                        title: getString("common.preferences.pageSize.title"),
                        options: defaultPagingOptions,
                    }}
                    visibleContentPreference={{
                        title: getString("common.preferences.visibleContentPreference.title"),
                        options: [
                            {
                                label: getString("common.preferences.visibleContentPreference.columns"),
                                options: preferences.visibleContentOptions,
                            },
                        ],
                    }}
                    preferences={preferences.currentPreferences}
                    onConfirm={preferences.onPreferencesConfirmHandler}
                />
            }
            columnDefinitions={data.currentColumnDefinitions}
        />
    );
};

export default ManageCatalogTable;
