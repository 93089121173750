import { PropertyFilterOperator } from "@amzn/awsui-collection-hooks";
import {
    Box,
    Button,
    CollectionPreferencesProps,
    Popover,
    PropertyFilterProps,
    SelectProps,
    SpaceBetween,
} from "@amzn/awsui-components-react";
import Icon from "@amzn/awsui-components-react/polaris/icon";
import Link from "@amzn/awsui-components-react/polaris/link";
import StatusIndicator, { StatusIndicatorProps } from "@amzn/awsui-components-react/polaris/status-indicator";
import { TableProps } from "@amzn/awsui-components-react/polaris/table";
import { StampedNote } from "@amzn/tinvent-typescript-client";
import CopyText from "common/CopyText/CopyText";
import { getString } from "common/ui-string-labels/ui-strings-utils";
import { SYSTEM_FULFILL_NAME } from "configuration/config";
import jp from "jsonpath";
import _, { isArray } from "lodash";
import moment from "moment";
import React from "react";
import { Link as LinkReactRouter } from "react-router-dom";

import CheckboxItem from "../../common/CheckboxItem/CheckboxItem";
import { FilesService } from "../../services/files-service";
import { ColumnSetting, VisibleOption } from "./config-models";

interface StatusInformation {
    status: StatusIndicatorProps.Type;
    message: string;
}
export interface SortingState {
    sortBy?: string;
    sortOrder?: string;
}

export const defaultPagingOptions = [
    {
        label: "10",
        value: 10,
    },
    {
        label: "30",
        value: 30,
    },
    {
        label: "50",
        value: 50,
    },
];

export const FILE_TYPE_RESULT = "result";
export const FILE_TYPE_ERROR = "error";
export const FILE_TYPE_DOWNLOAD = "download";
export const FILE_TYPE_UPLOAD = "upload";

export function buildColumnDefinitions(
    columnSettings: ColumnSetting[],
    onlyThese?: readonly string[],
): TableProps.ColumnDefinition<any>[] {
    const columnDefinitions: TableProps.ColumnDefinition<any>[] = [];
    for (const column of columnSettings) {
        const showIt: boolean = checkIfShowIt(column, onlyThese);
        if (!showIt) continue;
        const cellFunction = (item: any): React.ReactNode => {
            let value: any = null;
            let values: string[] = [];
            let id: any = null;
            if (isArray(column.selector)) {
                // Multiple values
                value = "";
                values = [];
                for (const selector of column.selector) {
                    const val = jp.value(item, selector as string);
                    value += " " + val;
                    values.push(val as string);
                }
            } else {
                // Single value
                // Uses jsonpath to get the value
                value = jp.value(item, column.selector as string);
            }

            let statusInfo: StatusInformation;
            let url: string;

            // Set display based on field type...
            switch (column.type) {
                case "date":
                    if (value) {
                        const timeAgo = moment(value as string).fromNow();
                        return <span title={value}>{timeAgo}</span>;
                    }
                    break;
                case "date_only":
                    if (value) {
                        const date = moment(value as string).format("MM/DD/YYYY");
                        return <span title={value}>{date}</span>;
                    }
                    break;

                case "date_utc":
                    if (value) {
                        const dateUtc = moment(value as string)
                            .utc()
                            .format("MM/DD/YYYY");
                        return <span title={value}>{dateUtc}</span>;
                    }
                    break;

                case "datetime_local":
                    if (value) {
                        const local = moment(value as string)
                            .local()
                            .format("MM/DD/YYYY HH:mm:ss");
                        return <span title={value}>{local}</span>;
                    }
                    break;

                case "assignee_alias":
                    return buildAssigneeAlias(value as string);

                case "user_info":
                    return buildUserInfo(values);

                case "status":
                    return buildStatusLabel(value);

                case "filestatus":
                    statusInfo = getFileStatusInformation(value);
                    return <StatusIndicator type={statusInfo.status}>{statusInfo.message}</StatusIndicator>;

                case "selectme":
                    return <CheckboxItem key={value} name="orderId" id={id}></CheckboxItem>;

                case "resultFile":
                    return buildResultFileLink(values);

                case "ordersHistoryFile":
                    return buildHistoryFileLink(values);

                case "ordersUploadFile":
                    return buildUploadOrderFileLink(values);

                case "errorFile":
                    return buildErrorFileLink(values);

                case "image_url":
                    return buildImageUrl(value as string, column.props);

                case "link":
                    url = "";
                    if (column.linkSetting) {
                        id = encodeURIComponent(jp.value(item, column.linkSetting.idSelector as string));
                        url = column.linkSetting.url.replace("{id}", id);
                    }
                    return (
                        <LinkReactRouter to={url} state={{ id, value }}>
                            {value}
                        </LinkReactRouter>
                    );

                case "uploadErrorsLink":
                    return buildUploadFileLink(values);
                case "uploadError":
                    return buildUploadError(value);
                case "uploadDetails":
                    return <div>{value}</div>;
                case "noteTooltip":
                    return buildNoteTooltip(value as StampedNote | undefined);
                default:
                    return <span>{value}</span>;
            }
        };

        columnDefinitions.push({
            ...column,
            cell: cellFunction,
            sortingField: column.sortable ? column.id : undefined,
        });
    }
    return columnDefinitions;
}

function getFileStatusInformation(status?: string): StatusInformation {
    switch (status) {
        case "UPLOADED":
            return { status: "in-progress", message: "Uploading file" };
        case "PROCESSING":
            return { status: "in-progress", message: "Processing file" };
        case "NOT_PROCESSED":
            return { status: "stopped", message: "File not processed" };
        case "COMPLETED":
            return { status: "success", message: "Success" };
        case "COMPLETED_WITH_ERRORS":
        default:
            return { status: "error", message: "Error" };
    }
}

function getOrdersUploadFileName(value: string) {
    const extension = ".xlsx";
    if (!value) {
        return "";
    }
    const parts = (value as string).split(extension);
    const fullFileName = parts[0];
    const displayName = fullFileName.split("-")[0] + extension;
    return displayName;
}

function buildUploadFileLink(values: string[]) {
    // TODO: Create link to the Orders
    if (values[0] === "COMPLETED_WITH_ERRORS") {
        const fileGuid = values[1];
        const fileName = getOrdersUploadFileName(values[2]);
        return (
            <LinkReactRouter to={`/orders/${fileGuid}`} state={{ fileGuid, fileName }}>
                {getString("uploadOrders.table.LinkToErrorsText")}
            </LinkReactRouter>
        );
    } else {
        return <span></span>;
    }
}

function buildUploadError(value: string) {
    if (value && value !== "") {
        return (
            <Popover
                size="small"
                position="top"
                triggerType="custom"
                dismissButton={false}
                content={<div>{value}</div>}
            >
                <Link>Errors</Link>
            </Popover>
        );
    } else {
        return <span></span>;
    }
}

// Status
const STATUS_MAPPING: { [key: string]: string } = {
    APPROVED: "info",
    CANCELLED: "error",
    COMPLETED: "success",
    DENIED: "error",
    NEW: "info",
    PENDING_APPROVAL: "pending",
    PROCESSING: "in-progress",
};
function buildStatusLabel(status: string): React.ReactNode {
    const lowerCaseStatus = (status as string).toLowerCase();
    const statusType: StatusIndicatorProps.Type = STATUS_MAPPING[status] as StatusIndicatorProps.Type;
    return (
        <span className="status">
            <StatusIndicator type={statusType}>{lowerCaseStatus}</StatusIndicator>
        </span>
    );
}

// Assignee Alias
function buildAssigneeAlias(value?: string): React.ReactNode {
    if (value) {
        return <Box variant={"p"}> {value !== SYSTEM_FULFILL_NAME ? `@${value}` : value} </Box>;
    }

    return (
        <Box color={"text-body-secondary"} variant={"p"}>
            Unassigned
        </Box>
    );
}

// User Alias
function buildUserInfo(values: string[]): React.ReactNode {
    const userAlias = values[0] ?? "alias unavailable";
    const userEmail = values[1] ?? "email unavailable";

    return (
        <SpaceBetween size="xs">
            <Link href={`https://phonetool.amazon.com/users/${userAlias}`} target={"_blank"}>
                @{userAlias}
            </Link>
            <CopyText textContent={userEmail} copyContent={userEmail} isHtml={false} />
        </SpaceBetween>
    );
}

// Image
interface ImageProps {
    width: string;
}
function buildImageUrl(theUrl: string, props: ImageProps): React.ReactNode {
    return <img src={theUrl} width={props.width} alt="logo" />;
}

function buildResultFileLink(values: string[]): React.ReactNode {
    return buildDownloadFileLink(values, FILE_TYPE_RESULT, FilesService.instance.downloadTinventFile);
}

function buildErrorFileLink(values: string[]): React.ReactNode {
    return buildDownloadFileLink(values, FILE_TYPE_ERROR, FilesService.instance.downloadTinventFile);
}

function buildHistoryFileLink(values: string[]): React.ReactNode {
    return buildDownloadFileLink(values, FILE_TYPE_DOWNLOAD, FilesService.instance.downloadOrdersHistory);
}

function buildUploadOrderFileLink(values: string[]): React.ReactNode {
    return buildDownloadFileLink(values, FILE_TYPE_UPLOAD, FilesService.instance.downloadOrdersHistory);
}

function validateDownloadParams(values: string[], downloadType: string) {
    let isValid: boolean = true;
    if (!values) {
        isValid = false;
    }

    if (values.length < 2 || values.length > 3) {
        isValid = false;
    }

    const fileId: string = values[0];
    let fileName: string = values[1];

    if (!fileId || !fileName) {
        isValid = false;
    }

    if (downloadType !== FILE_TYPE_DOWNLOAD && downloadType !== FILE_TYPE_UPLOAD) {
        const status: string = values[2];

        if (!status) {
            isValid = false;
        }

        if (isValid && downloadType === FILE_TYPE_ERROR) {
            fileName = `${fileName.split(".")[0]}_errors.xlsx`;
        }

        if (isValid && downloadType === FILE_TYPE_ERROR && status !== "COMPLETED_WITH_ERRORS") {
            isValid = false;
        }
    }

    if (downloadType === FILE_TYPE_UPLOAD) {
        fileName = getOrdersUploadFileName(fileName);
    }

    return { isValid, fileId, fileName };
}

function buildDownloadFileLink(
    values: string[],
    downloadType: string,
    onFollow: (fileId: string, fileName: string, downloadType: string) => void,
): React.ReactNode {
    const { isValid, fileId, fileName } = validateDownloadParams(values, downloadType);
    if (!isValid) {
        return <span></span>;
    }

    return (
        <SpaceBetween direction="horizontal" size="xs">
            <Icon name="download" variant="link" />
            <Link data-testid="download-file" href="#" onFollow={() => onFollow(fileId, fileName, downloadType)}>
                {fileName}
            </Link>
        </SpaceBetween>
    );
}

///////////////////
export function buildFilteringProperties(columnSettings: ColumnSetting[]): PropertyFilterProps.FilteringProperty[] {
    const filters: PropertyFilterProps.FilteringProperty[] = [];
    for (const column of columnSettings) {
        if (!column.operators && !column.defaultOperator) continue;
        filters.push({
            key: column.id,
            propertyLabel: column.header,
            operators: column.operators ? (column.operators as PropertyFilterOperator[]) : undefined,
            defaultOperator: column.defaultOperator ? (column.defaultOperator as PropertyFilterOperator) : undefined,
            groupValuesLabel: column.header,
        });
    }
    return filters;
}

/**
 * Prepares the options for the preferences, to set visible columns
 * @param columnSettings The settings from the json file
 * @returns The array of options for visible content
 */
export function buildVisibleContentOptions(
    columnSettings: ColumnSetting[],
): CollectionPreferencesProps.VisibleContentOption[] {
    const options: CollectionPreferencesProps.VisibleContentOption[] = [];
    for (const column of columnSettings) {
        if (column.visible === VisibleOption.never) continue;

        options.push({
            id: column.id,
            label: column.header,
            editable: column.visible !== VisibleOption.always,
        });
    }
    return options;
}

/**
 * Returns Cloudscape Select Options from an Array of strings
 * @param array The list of strings you want as options
 * @returns The options for the Select
 */
export function buildSelectOptionsFromStrings(array: string[]) {
    const selectOptions: SelectProps.Option[] = array.map((stringValue) => {
        return { label: stringValue, value: stringValue } as SelectProps.Option;
    });
    return selectOptions;
}

function checkIfShowIt(column: ColumnSetting, onlyThese: readonly string[] | undefined): boolean {
    if (column.visible === VisibleOption.never) {
        return false;
    }
    if (column.visible === VisibleOption.always) {
        return true;
    }
    if (!onlyThese) {
        if (column.visible === VisibleOption.no) {
            // Hidden by default
            return false;
        }

        // Visible by default
        return true;
    } else {
        // Check based on selected preferences
        return onlyThese.indexOf(column.id) >= 0;
    }
}
function buildNoteTooltip(stampedNote?: StampedNote): React.ReactNode {
    return (
        stampedNote && (
            <Popover
                dismissButton={false}
                position="top"
                size="small"
                triggerType="custom"
                content={
                    <>
                        <span>{stampedNote?.note}</span>
                        <br />
                        <small>
                            {stampedNote?.by} {moment(stampedNote?.on?.toISOString()).fromNow()}
                        </small>
                    </>
                }
            >
                <Button iconName="status-info" variant="icon" />
            </Popover>
        )
    );
}
